define('bc-frontend/components/verb-chart/segment/component', ['exports', 'ember', 'bc-frontend/utils/color-generator'], function (exports, _ember, _bcFrontendUtilsColorGenerator) {
  exports['default'] = _ember['default'].Component.extend({
    classNameBindings: [':segment', 'segment.state:segment-active', 'segment.change.selected:segment-selected'],
    attributeBindings: ['style'],

    segmentVerb: _ember['default'].computed.alias('segment.verb'),
    instantaneous: _ember['default'].computed.alias('segmentVerb.instantaneous'),
    associatedChange: _ember['default'].computed.alias('segment.change'),
    state: _ember['default'].computed.alias('segment.state'),
    isSelected: _ember['default'].computed.alias('associatedChange.selected'),

    color: _ember['default'].computed('segmentVerb.key', function () {
      return (0, _bcFrontendUtilsColorGenerator['default'])(this.get('segmentVerb.key'));
    }),

    click: function click() {
      this.sendAction('onClick', this.get('segment'));
    },

    style: _ember['default'].computed('segment.width', 'instantaneous', 'isSelected', 'color', 'state', function () {
      var width = this.get('segment.width');
      var selected = this.get('isSelected'),
          state = this.get('state');

      if (this.get('instantaneous')) {
        width = width / 4;
      }

      if (!selected && state) {
        var color = this.get('color');
        return _ember['default'].String.htmlSafe('width: ' + width + '%;background-color:' + color + ';');
      } else {
        return _ember['default'].String.htmlSafe('width: ' + width + '%;');
      }
    })
  });
});