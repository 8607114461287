define('bc-frontend/application/route', ['exports', 'ember', 'ember-simple-auth/mixins/application-route-mixin', 'ember-cli-routing-service/routes/transition-to-listener'], function (exports, _ember, _emberSimpleAuthMixinsApplicationRouteMixin, _emberCliRoutingServiceRoutesTransitionToListener) {
  exports['default'] = _emberCliRoutingServiceRoutesTransitionToListener['default'].extend(_emberSimpleAuthMixinsApplicationRouteMixin['default'], {
    dsUpdates: _ember['default'].inject.service(),

    sessionInvalidated: function sessionInvalidated() {
      // default invalidation sometimes leaves the last route
      this.transitionTo('/');
      window.location.replace('/');
    },

    activate: function activate() {
      this.get('dsUpdates').initialize();
    }
  });
});