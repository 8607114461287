define('bc-frontend/helpers/gcd', ['exports', 'ember-math-helpers/helpers/gcd'], function (exports, _emberMathHelpersHelpersGcd) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberMathHelpersHelpersGcd['default'];
    }
  });
  Object.defineProperty(exports, 'gcd', {
    enumerable: true,
    get: function get() {
      return _emberMathHelpersHelpersGcd.gcd;
    }
  });
});