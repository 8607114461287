define('bc-frontend/components/dataset-detail/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: null,

    dataSet: null,
    schema: _ember['default'].computed.alias('dataSet.schema'),
    schemaSlug: _ember['default'].computed.alias('schema.slug'),

    tileKey: _ember['default'].computed('schemaSlug', function () {
      var schemaSlug = this.get('schemaSlug');
      if (schemaSlug) {
        return 'dataset-detail/layouts/' + schemaSlug;
      }
      return null;
    }),

    actions: {
      modifiedChanged: function modifiedChanged(flag) {
        this.sendAction('onModifiedChanged', flag);
      }
    }
  });
});