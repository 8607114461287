define('bc-frontend/components/s3-upload/component', ['exports', 'ember', 'npm:evaporate', 'npm:aws-sdk', 'bc-frontend/config/environment'], function (exports, _ember, _npmEvaporate, _npmAwsSdk, _bcFrontendConfigEnvironment) {

  var bind = _ember['default'].run.bind;
  var SUPPORTED_EXTENSIONS = ['avi', 'asf', 'mkv', 'mov', 'mpg', 'mpeg', 'mp2', 'mp4', 'wmv'];

  var evaporateConfig = {
    aws_key: _bcFrontendConfigEnvironment['default']['s3-upload-key'],
    bucket: _bcFrontendConfigEnvironment['default']['s3-upload-bucket'],
    awsRegion: _bcFrontendConfigEnvironment['default']['s3-upload-region'],
    // customAuth: null
    computeContentMd5: true,
    cryptoMd5Method: function cryptoMd5Method(data) {
      return _npmAwsSdk['default'].util.crypto.md5(data, 'base64');
    },
    cryptoHexEncodedHash256: function cryptoHexEncodedHash256(data) {
      return _npmAwsSdk['default'].util.crypto.sha256(data, 'hex');
    },
    onlyRetryForSameFileName: true
  };

  exports['default'] = _ember['default'].Component.extend({
    uploadAuth: _ember['default'].inject.service(),

    classNameBindings: ['isHovering:is-hovering', 'isWaiting:s3-upload', 'isWaiting:layout-column'],
    classNames: ['layout-align-center-center'],
    acceptTypes: 'video/*',
    allowMultiple: true,

    isWaiting: _ember['default'].computed.not('uploads.length'),

    multiple: _ember['default'].computed('allowMultiple', function () {
      return this.get('allowMultiple') ? 'multiple' : null;
    }),
    isHovering: false,

    uploads: _ember['default'].computed(function () {
      return _ember['default'].A([]);
    }),
    uploadSort: ['active:desc'],
    sortedUploads: _ember['default'].computed.sort('uploads', 'uploadSort'),
    inProgress: _ember['default'].computed.filterBy('uploads', 'active', true),

    setup: (function () {
      var handleDrag = bind(this, this.handleDrag),
          handleDrop = bind(this, this.handleDrop),
          handleChange = bind(this, this.handleChange);

      this.$().on({
        dragover: handleDrag,
        dragleave: handleDrag,
        drop: handleDrop
      });

      this.$('input:file').on('change', handleChange);

      this.registerComponent();
    }).on('didInsertElement'),

    handleDrag: function handleDrag(evt) {
      if (evt.type === 'dragover') {
        this.startHover();
      } else {
        this.stopHover();
      }

      return false;
    },

    handleDrop: function handleDrop(evt) {
      this.stopHover();
      this.handleFiles(evt.dataTransfer.files);
      return false;
    },

    handleChange: function handleChange(evt) {
      this.handleFiles(evt.target.files || null);
    },

    handleFiles: function handleFiles(files) {
      var self = this;
      var validatedFiles = Array.from(files).filter(function (file) {
        return file && self.validate(file);
      });

      if (validatedFiles.length < files.length) {
        alert("A file you've selected for upload does not appear to be a supported format. Please export or convert the files to AVI, MPG, MP4, MKV, WMV, ASF or WEBM and try again.");
      }

      if (validatedFiles.length > 0) {
        self.startUploads(validatedFiles);
      }
    },

    startUploads: function startUploads(files) {
      var self = this,
          uploadAuth = this.get('uploadAuth'),
          test = this.get('test'),

      //schemaSlug = this.get('schema'),
      schemaSlug = 'file',
          config = Object.assign({
        customAuthMethod: function customAuthMethod() {/*filler*/}
      }, evaporateConfig);

      _npmEvaporate['default'].create(config).then(function (evaporate) {
        files.forEach(function (file) {
          uploadAuth.authorizeUpload.bind(uploadAuth)(file.name, test, schemaSlug).then(function (authorization) {
            var upload = _ember['default'].Object.create({
              fileName: file.name,
              objectKey: authorization.objectKey,
              progress: 0,
              active: true,
              failed: false,
              completed: false,
              reason: ''
            });
            self.get('uploads').pushObject(upload);
            var addConfig = {
              name: authorization.objectKey,
              file: file,
              progress: function progress(progressValue) {
                // console.log('Progress', progressValue);
                upload.set('progress', Math.round(progressValue * 100));
              },
              complete: function complete() {
                // console.log('Complete!');
                uploadAuth.finalizeUpload.bind(uploadAuth, authorization.id)().then(function () {
                  // self.get('uploads').removeObject(upload);
                  upload.setProperties({ completed: true, active: false });
                  self.checkAndClose.bind(self)();
                });
              }
            },
                override = {
              customAuthMethod: uploadAuth.signRequest.bind(uploadAuth, authorization.id)
            };

            evaporate.add(addConfig, override).then(function () {
              //console.log('File successfully uploaded to:', awsObjectKey);
            }, function (reason) {
              //console.log('File did not upload sucessfully:', reason);
              upload.setProperties({ failed: true, active: false, reason: reason });
              self.checkAndClose.bind(self)();
            });
          });
        });
      });
    },

    checkAndClose: function checkAndClose() {
      if (!this.get('inProgress.length')) {
        this.sendAction('onComplete');
      }
    },

    validate: function validate(file) {
      var fileExt = file.name.slice((file.name.lastIndexOf(".") - 1 >>> 0) + 2).toLowerCase(),
          supportedExtensions = SUPPORTED_EXTENSIONS;
      return supportedExtensions.indexOf(fileExt) >= 0;
    },

    startHover: function startHover() {
      this.set('isHovering', true);
    },

    stopHover: function stopHover() {
      this.set('isHovering', false);
    },

    teardown: (function () {
      var handleDrag = bind(this, this.handleDrag),
          handleDrop = bind(this, this.handleDrop),
          handleChange = bind(this, this.handleChange);

      this.$().off({
        dragover: handleDrag,
        dragleave: handleDrag,
        drop: handleDrop
      });

      this.$('input:file').off('change', handleChange);
    }).on('willDestroyElement'),

    registerComponent: function registerComponent() {
      this.sendAction('onInitialized', this.get('uploads'));
    }
  });
});