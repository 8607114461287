define('bc-frontend/data-set-schema/supports-sampling', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Object.create({
    'spontaneous-alternation': false,
    'analysis': true,
    'photobeam-activity': true,
    'centroid': false,
    'manual-scoring-analysis': false,
    'photobeam-activity': true,
    'zone-sequence': false,
    'zoned-analysis': true
  });
});