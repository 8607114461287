define('bc-frontend/tests/route', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    queryParams: {
      includeArchived: {
        refreshModel: true
      },
      searchParam: {
        refreshModel: true
      }
    },

    model: function model(params) {
      var qParams = {
        owned: true
      };
      if (params.includeArchived) {
        qParams.with_archived = true;
      }
      if (params.searchParam) {
        qParams.search = params.searchParam;
      }
      return this.get('store').query('test', qParams);
    }
  });
});