define('bc-frontend/helpers/regexp-replace', ['exports', 'ember-string-helpers/helpers/regexp-replace'], function (exports, _emberStringHelpersHelpersRegexpReplace) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberStringHelpersHelpersRegexpReplace['default'];
    }
  });
  Object.defineProperty(exports, 'regexpReplace', {
    enumerable: true,
    get: function get() {
      return _emberStringHelpersHelpersRegexpReplace.regexpReplace;
    }
  });
});