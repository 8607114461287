define('bc-frontend/components/device-preview/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    store: _ember['default'].inject.service(),

    dataSets: _ember['default'].computed('device.id', function () {
      return this.get('store').query('dataSet', {
        device: this.get('device.id')
      });
    }),

    isLoading: _ember['default'].computed.alias('dataSets.isPending')
  });
});