define('bc-frontend/components/manual-scoring-configuration-editor/new-behavior-dialog/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    behaviorType: 'duration',

    actions: {
      confirm: function confirm() {
        var label = this.get('label'),
            behaviorType = this.get('behaviorType');

        this.sendAction('onCreate', label, behaviorType);
      },
      cancel: function cancel() {
        this.sendAction('onClose');
      }
    }
  });
});