define('bc-frontend/components/tests/download-warning-dialog/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    exportLink: null, // should be supplied as param

    actions: {
      cancel: function cancel() {
        this.sendAction('onClose');
      }
    }
  });
});