define('bc-frontend/helpers/pad', ['exports', 'ember-pad/helpers/pad'], function (exports, _emberPadHelpersPad) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberPadHelpersPad['default'];
    }
  });
  Object.defineProperty(exports, 'pad', {
    enumerable: true,
    get: function get() {
      return _emberPadHelpersPad.pad;
    }
  });
});