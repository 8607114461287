define('bc-frontend/components/device-preview/sandiegoinstruments-activitychamber/component', ['exports', 'ember', 'bc-frontend/mixins/device-preview-mixin', 'npm:base64-js'], function (exports, _ember, _bcFrontendMixinsDevicePreviewMixin, _npmBase64Js) {

  var MAP_MASK = [{
    axis: 'x',
    beams: [[3, 0x01], [2, 0x01], [1, 0x01], [0, 0x01]]
  }, {
    axis: 'y',
    beams: [[3, 0x02], [2, 0x02], [1, 0x02], [0, 0x02], [3, 0x04], [2, 0x04], [1, 0x04], [0, 0x04]]
  },
  // [3, 0x08],
  // [2, 0x08],
  // [1, 0x08],
  // [0, 0x08],
  // [0, 0x20],
  // [1, 0x20],
  // [2, 0x20],
  // [3, 0x20],
  // [0, 0x10],
  // [1, 0x10],
  // [2, 0x10],
  // [3, 0x10],
  // [0, 0x80],
  // [1, 0x80],
  // [2, 0x80],
  // [3, 0x80],
  // [0, 0x40],
  // [1, 0x40],
  // [2, 0x40],
  // [3, 0x40],
  {
    axis: 'z',
    beams: [[31, 0x01], [30, 0x01], [29, 0x01], [28, 0x01], [31, 0x02], [30, 0x02], [29, 0x02], [28, 0x02]]
  }];

  // [31, 0x04],
  // [30, 0x04],
  // [29, 0x04],
  // [28, 0x04],
  // [31, 0x08],
  // [30, 0x08],
  // [29, 0x08],
  // [28, 0x08]
  exports['default'] = _ember['default'].Component.extend(_bcFrontendMixinsDevicePreviewMixin['default'], {
    isWaiting: true,
    lastSample: {},

    // isWaiting: false,
    // lastSample: [
    //   [true, false, false, false, true, false, false, false, true, false, false, false, true, false, false, false],
    //   [true, false, false, false, true, false, false, false, true, false, false, false, true, false, false, false],
    //   [true, false, false, false, true, false, false, false, true, false, false, false, true, false, false, false],
    // ],
    mask: MAP_MASK,

    dataReceived: function dataReceived(payload) {
      var decoded = _npmBase64Js['default'].toByteArray(payload);

      var mapped = MAP_MASK.map(function (axis) {
        return axis.beams.map(function (beam) {
          return !!(decoded[beam[0]] & beam[1]);
        });
      });

      //debugger;

      this.set('isWaiting', false);
      this.set('lastSample', mapped);
    }
  });
});