define('bc-frontend/components/test-tile/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    // paperToaster: Ember.inject.service(),
    router: _ember['default'].inject.service(),

    actions: {
      viewTest: function viewTest() {
        var model = this.get('model');
        // paperToaster = this.get('paperToaster');
        if (!model.get('deletedAt')) {
          this.get('router').transitionTo('test', model);
        } else {
          // TODO: need to upgrade ember-paper first to utilize
          // toast messages.
          // paperToaster.show('Please unarchive test to view it.', {
          //   duration: 4000
          // });

          alert('Please unarchive test to view it.');
        }
      }
    }
  });
});