define('bc-frontend/ds-updates/service', ['exports', 'ember'], function (exports, _ember) {

  var USER_MESSAGES = _ember['default'].A([]);

  var TEST_MESSAGES = _ember['default'].A(['calibration_started', 'calibration_completed', 'clearing_started', 'clearing_completed', 'inserting_started', 'inserting_completed', 'collection_completed', 'save_pending', 'conversion_started', 'conversion_completed', 'append_started']);

  var DS_MESSAGES = _ember['default'].A(['calibration_update', 'thumbnail_updated', 'append', 'append_started']);

  var ALL_MESSAGES = _ember['default'].A(USER_MESSAGES.toArray()).pushObjects(TEST_MESSAGES).pushObjects(DS_MESSAGES);

  exports['default'] = _ember['default'].Service.extend({
    pusher: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),
    store: _ember['default'].inject.service(),

    userId: _ember['default'].computed.alias('session.data.authenticated.userId'),

    userChannel: null,
    testChannel: null,
    dsChannel: null,

    watchedTest: null,
    watchedDS: null,
    testController: null,
    schemaController: null,
    dsController: null,

    initialize: function initialize() {
      var isAuthenticated = this.get('session.isAuthenticated');

      if (isAuthenticated) {
        this.subscribe();
      }
    },

    sessionChanged: _ember['default'].observer('session.isAuthenticated', function () {
      var isAuthenticated = this.get('session.isAuthenticated');

      if (isAuthenticated) {
        this.subscribe();
      } else {
        this.unsubscribe();
      }
    }),

    subscribe: function subscribe() {
      var self = this,
          pusher = this.get('pusher.pusher'),
          userId = this.get('userId'),
          watchedTest = this.get('watchedTest'),
          watchedDS = this.get('watchedDS');

      // subscribe to user channel
      var userChannel = pusher.subscribe('presence-user.' + userId);
      this.set('userChannel', userChannel);

      ALL_MESSAGES.forEach(function (msg) {
        userChannel.bind(msg, self.handleMessage.bind(self, msg));
      });

      if (watchedTest) {
        this.subscribeTest(watchedTest);
      }

      if (watchedDS) {
        this.subscribeDS(watchedDS);
      }
    },

    unsubscribe: function unsubscribe() {
      var pusher = this.get('pusher.pusher'),
          userId = this.get('userId'),
          userChannel = this.get('userChannel'),
          watchedTest = this.get('watchedTest'),
          watchedDS = this.get('watchedDS');

      if (userChannel && userId) {
        pusher.unsubscribe('presence-user.' + userId);
      }

      if (watchedTest) {
        this.unsubscribeTest(watchedTest);
      }

      if (watchedDS) {
        this.unsubscribeDS(watchedDS);
      }
    },

    activateTest: function activateTest(controller, test) {
      this.set('watchedTest', test);
      this.set('testController', controller);

      if (test) {
        this.subscribeTest(test);
      }
    },

    deactivateTest: function deactivateTest() {
      var test = this.get('watchedTest');

      if (test) {
        this.unsubscribeTest(test);
      }

      this.set('watchedTest', null);
      this.set('testController', null);
    },

    activateSchema: function activateSchema(controller) {
      this.set('schemaController', controller);
    },

    deactivateSchema: function deactivateSchema() {
      this.set('schemaController', null);
    },

    subscribeTest: function subscribeTest(test) {
      // subscribe to DS channel
      var pusher = this.get('pusher.pusher'),
          testChannel = pusher.subscribe('presence-test.' + test),
          self = this;

      this.set('testChannel', testChannel);

      TEST_MESSAGES.forEach(function (msg) {
        testChannel.bind(msg, self.handleMessage.bind(self, msg));
      });
    },

    unsubscribeTest: function unsubscribeTest(test) {
      var pusher = this.get('pusher.pusher'),
          testChannel = this.get('testChannel');

      if (testChannel && test) {
        pusher.unsubscribe('presence-test.' + test);
      }
    },

    activateDS: function activateDS(controller, ds) {
      this.set('watchedDS', ds);
      this.set('dsController', controller);

      if (ds) {
        this.subscribeDS(ds);
      }
    },

    deactivateDS: function deactivateDS() {
      var ds = this.get('watchedDS');

      if (ds) {
        this.unsubscribeDS(ds);
      }

      this.set('watchedDS', null);
      this.set('dsController', null);
    },

    subscribeDS: function subscribeDS(ds) {
      // subscribe to DS channel
      var pusher = this.get('pusher.pusher'),
          dsChannel = pusher.subscribe('presence-dataset.' + ds),
          self = this;

      this.set('dsChannel', dsChannel);

      DS_MESSAGES.forEach(function (msg) {
        dsChannel.bind(msg, self.handleMessage.bind(self, msg));
      });
    },

    unsubscribeDS: function unsubscribeDS(ds) {
      var pusher = this.get('pusher.pusher'),
          dsChannel = this.get('dsChannel');

      if (dsChannel && ds) {
        pusher.unsubscribe('dataset.' + ds);
      }
    },

    handleMessage: function handleMessage(message, data) {
      this.userHandler(message, data);
      this.testHandler(message, data);
      this.dsHandler(message, data);
    },

    userHandler: function userHandler(message, data) {
      if (USER_MESSAGES.includes(message)) {
        var dsId = data.dataset,
            store = this.get('store');

        if (!dsId) {
          return;
        }

        var ds = store.peekRecord('dataSet', dsId);

        if (ds) {
          ds.reload();
        }
      }
    },

    testHandler: function testHandler(message, data) {
      if (TEST_MESSAGES.includes(message)) {
        var schemaController = this.get('schemaController'),
            dsId = data.dataset,
            handlerName = ('on_' + message).camelize(),
            store = this.get('store');

        if (!dsId) {
          return;
        }

        var ds = store.peekRecord('dataSet', dsId);

        if (ds) {
          ds.reload();
        }

        if (schemaController && schemaController[handlerName]) {
          schemaController[handlerName].bind(schemaController)(data);
        }
      }
    },

    dsHandler: function dsHandler(message, data) {
      var controller = this.get('dsController'),
          dsId = data.dataset,
          handlerName = ('on_' + message).camelize(),
          watchedDS = this.get('watchedDS');

      if (dsId == watchedDS) {
        if (controller && controller[handlerName]) {
          controller[handlerName].bind(controller)(data);
        }
      }
    }
  });
});