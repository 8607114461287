define('bc-frontend/mixins/prevent-transition-mixin', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    actions: {
      willTransition: function willTransition(transition) {
        if (this.get('controller.preventTransition')) {
          if (!confirm('You have unsaved changes. Are you sure you want to leave?')) {
            transition.abort();
            // hacky fix to prevent the URL from getting out of sync
            if (window.history) {
              window.history.forward();
            }
          } else {
            this.get('controller').set('preventTransition', false);
          }
        }
      }
    }
  });
});