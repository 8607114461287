define('bc-frontend/upload-auth/service', ['exports', 'ember'], function (exports, _ember) {

  var FILE_TO_VIDEO_ANALYZER_ID = 10;

  exports['default'] = _ember['default'].Service.extend({
    ajax: _ember['default'].inject.service(),
    store: _ember['default'].inject.service(),

    authorizeUpload: function authorizeUpload(name, test, slug) {
      var ajax = this.get('ajax'),
          store = this.get('store');

      return store.queryRecord('dataSetSchema', { slug: slug }).then(function (schema) {
        var payload = {
          dataSet: {
            schemaId: schema.get('id'),
            testId: test.get('id'),
            subject: name
          }
        };

        return ajax.request('/api/dataSets/upload', {
          method: 'post',
          data: JSON.stringify(payload),
          contentType: 'application/json'
        });
      }).then(function (dataset) {
        return {
          id: dataset.dataSet.id,
          objectKey: dataset.dataSet.dataInternalUri
        };
      });
    },

    signRequest: function signRequest(dataSetId, signParams, signHeaders, stringToSign, signatureDateTime, canonicalRequest) {
      var ajax = this.get('ajax'),
          payload = {
        signParams: signParams,
        signHeaders: signHeaders,
        stringToSign: stringToSign,
        signatureDateTime: signatureDateTime,
        canonicalRequest: canonicalRequest
      };

      return ajax.request('/api/dataSets/' + dataSetId + '/sign', {
        method: 'post',
        data: JSON.stringify(payload),
        contentType: 'application/json'
      }).then(function (result) {
        return result.dataSet.signature;
      });
    },

    finalizeUpload: function finalizeUpload(dataSetId) {
      var ajax = this.get('ajax'),
          payload = {
        analyzer: FILE_TO_VIDEO_ANALYZER_ID
      };

      return ajax.request('/api/dataSets/' + dataSetId + '/finalize_upload', {
        method: 'put',
        data: JSON.stringify(payload),
        contentType: 'application/json'
      }).then(function (result) {
        return result;
      });
    }
  });
});