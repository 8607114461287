define('bc-frontend/ajax/service', ['exports', 'ember', 'ember-ajax/services/ajax', 'bc-frontend/config/environment'], function (exports, _ember, _emberAjaxServicesAjax, _bcFrontendConfigEnvironment) {
  exports['default'] = _emberAjaxServicesAjax['default'].extend({
    session: _ember['default'].inject.service(),
    host: _bcFrontendConfigEnvironment['default']['apiHost'],
    headers: _ember['default'].computed('session.session.authenticated.token', {
      get: function get() {
        var headers = {};
        this.get('session').authorize('authorizer:token', function (headerName, headerValue) {
          headers[headerName] = headerValue;
        });
        return headers;
      }
    })
  });
});
// app/services/ajax.js