define('bc-frontend/test/index/route', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    ajax: _ember['default'].inject.service()

  });
});
// model() {
//   // return this.get('store').query('dataSet', {test: this.modelFor('test').get('id')});
//   let testId = this.modelFor('test').get('id');
//   return this.get('ajax').request(`/api/tests/${testId}/schemas`);
// }