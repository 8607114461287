define('bc-frontend/user-session/service', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({
    session: _ember['default'].inject.service(),
    store: _ember['default'].inject.service(),
    intercom: _ember['default'].inject.service(),

    userId: _ember['default'].computed.alias('session.data.authenticated.userId'),

    user: _ember['default'].computed('session.isAuthenticated', function () {
      var userId = this.get('userId');
      if (userId) {
        return this.get('store').findRecord('user', userId);
      }
      return null;
    }),

    userChanged: _ember['default'].observer('user.isFulfilled', function () {
      var user = this.get('user'),
          intercom = this.get('intercom');

      if (user && user.get('isFulfilled')) {
        var firstName = user.get('firstName'),
            lastName = user.get('lastName');

        intercom.set('user.email', user.get('email'));
        intercom.set('user.name', firstName + ' ' + lastName);
        intercom.start();

        // This is an example script - don't forget to change it!
        FS.identify(user.get('email'), {
          displayName: firstName + ' ' + lastName,
          email: user.get('email')
        });
      }
    }).on('init')
  });
});
/*globals FS */