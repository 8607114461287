define('bc-frontend/test/photobeam/controller', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var inject = _ember['default'].inject;
  exports['default'] = _ember['default'].Controller.extend({
    testController: inject.controller('test'),
    test: computed.alias('testController.model'),
    schema: null,

    dataSets: computed.alias('model'),
    dataSetsCount: computed.alias('dataSets.length'),
    hasData: computed.gte('dataSetsCount', 1),

    showCollectButton: computed.alias('model.meta.canCollect'),

    isRecorded: computed.alias('test.liveTest'),
    isUploaded: computed.not('isRecorded'),

    shouldUpdateStations: null,

    onCollectionCompleted: function onCollectionCompleted(data) {
      if (data.status === 'completed') {
        if (data.next) {
          this.set('showCaptureDataSet', true);
          this.set('shouldUpdateStations', new Date());
        }
      }
    },

    actions: {
      refresh: function refresh() {
        this.get('target.router').refresh();
      },
      closeCapture: function closeCapture(refresh) {
        this.set('showCaptureDataSet', false);
        if (refresh) this.get('target.router').refresh();
      }
    }
  });
});