define('bc-frontend/components/change-alert/component', ['exports', 'ember', 'ember-local-storage'], function (exports, _ember, _emberLocalStorage) {
  exports['default'] = _ember['default'].Component.extend({
    alerts: (0, _emberLocalStorage.storageFor)('alerts'),

    showAlert: _ember['default'].computed('alerts', 'alertKey', function () {
      var alerts = this.get('alerts');
      return !alerts.get(this.get('alertKey'));
    }),

    dismissAlert: function dismissAlert() {
      var alerts = this.get('alerts');
      alerts.set(this.get('alertKey'), true);
      this.set('showAlert', false);
    },

    actions: {
      dismiss: function dismiss() {
        this.dismissAlert();
      }
    }
  });
});