define('bc-frontend/components/smart-video/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: null,

    _setup: (function () {
      this.setBindings();
    }).on('didRender'),

    setBindings: function setBindings() {
      var self = this;

      var video = self.$("#video-preview")[0];
      video.ontimeupdate = self.timeUpdated.bind(self);
      video.ondurationchange = self.durationChanged.bind(self);
    },

    removeBindings: function removeBindings() {
      var video = self.$("#video-preview")[0];
      video.ontimeupdate = null;
      video.ondurationchange = null;
    },

    timeUpdated: function timeUpdated() {
      this.sendAction('onPositionChanged', this.$("#video-preview")[0].currentTime);
    },

    durationChanged: function durationChanged() {
      this.sendAction('onDurationChanged', this.$("#video-preview")[0].duration);
    },

    _teardown: (function () {
      this.removeBindings();
    }).on('willRemoveElement')
  });
});