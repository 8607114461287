define('bc-frontend/components/verb-chart/component', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {

  // const rangeOptions = [15, 60, 300, 1200, 3600];

  exports['default'] = _ember['default'].Component.extend({
    classNames: ['verb-chart'],

    // 4 required parameters -- minTS and maxTS representing the absolute
    // beginning and end of the video clip; position representing the current
    // position in absolute time; and data representing the expanded verb data
    minTS: null,
    maxTS: null,
    position: null,
    data: [],
    verbs: [],

    duration: _ember['default'].computed('minTS', 'maxTS', function () {
      var minTS = this.get('minTS'),
          maxTS = this.get('maxTS');
      if (!maxTS) {
        return null;
      }
      return maxTS.diff(minTS, 'seconds', true);
    }),

    targetRange: 15,

    rangeCollection: _ember['default'].computed.collect('duration', 'targetRange'),
    effectiveRange: _ember['default'].computed.min('rangeCollection'),

    halfRange: _ember['default'].computed('effectiveRange', function () {
      return this.get('effectiveRange') / 2;
    }),

    windowStart: _ember['default'].computed('minTS', 'maxTS', 'effectiveRange', 'position', function () {
      var effectiveRange = this.get('effectiveRange'),
          halfRange = effectiveRange / 2,
          minTS = this.get('minTS'),
          maxTS = this.get('maxTS'),
          position = this.get('position');

      var naiveStart = (0, _moment['default'])(position).subtract(halfRange, 'seconds');
      var naiveEnd = (0, _moment['default'])(position).add(halfRange, 'seconds');

      if (naiveStart < minTS) {
        return minTS;
      } else if (naiveEnd > maxTS) {
        return (0, _moment['default'])(maxTS).subtract(effectiveRange, 'seconds');
      }

      return naiveStart;
    }),

    windowEnd: _ember['default'].computed('minTS', 'maxTS', 'effectiveRange', 'position', function () {
      var effectiveRange = this.get('effectiveRange'),
          halfRange = effectiveRange / 2,
          minTS = this.get('minTS'),
          maxTS = this.get('maxTS'),
          position = this.get('position');

      var naiveStart = (0, _moment['default'])(position).subtract(halfRange, 'seconds');
      var naiveEnd = (0, _moment['default'])(position).add(halfRange, 'seconds');

      if (naiveStart < minTS) {
        return (0, _moment['default'])(minTS).add(effectiveRange, 'seconds');
      } else if (naiveEnd > maxTS) {
        return maxTS;
      }

      return naiveEnd;
    }),

    dataIndex: function dataIndex(position) {
      var data = this.get('data');

      if (position <= this.get('minTS')) {
        return 0;
      }
      if (position >= this.get('maxTS')) {
        return data.length - 1;
      }

      return data.reduce(function (value, state, index) {
        if (value) return value;
        if (state.start <= position && state.end > position) return index;
        return null;
      }, null);
    },

    // TODO: revisit data slicing/windowing later; this was causing extensive
    // unintentional DOM re-rendering as rendering engine doesn't recognize
    // that the sliced data represents much of the same dom nodes.

    // dataStart: Ember.computed('windowStart', 'data', function() {
    //   return this.dataIndex(this.get('windowStart'));
    // }),
    //
    // dataEnd: Ember.computed('windowEnd', 'data', function() {
    //   return this.dataIndex(this.get('windowEnd'));
    // }),

    dataStart: _ember['default'].computed('data', function () {
      return 0;
    }),

    dataEnd: _ember['default'].computed('data', function () {
      return this.get('data').length - 1;
    }),

    slicedData: _ember['default'].computed('dataStart', 'dataEnd', 'data', function () {
      var startIndex = this.get('dataStart'),
          endIndex = this.get('dataEnd'),
          data = this.get('data');

      return data.slice(startIndex, endIndex + 1);
    }),

    trackedData: _ember['default'].computed('slicedData', 'verbs', function () {
      var data = this.get('slicedData'),
          verbs = this.get('verbs'),
          effectiveRange = this.get('effectiveRange');

      var tracks = verbs.map(function (verb, verbIndex) {
        return data.map(function (cell) {
          return {
            start: cell.start,
            end: cell.end,
            state: cell.state[verbIndex],
            width: 100 * (cell.end.diff(cell.start, 'seconds', true) / effectiveRange),
            change: cell.change,
            verb: verb
          };
        });
      });

      return tracks.map(function (verb) {
        var consolidated = [],
            lastSpan = null;

        verb.forEach(function (cell) {
          if (!lastSpan) {
            lastSpan = cell;
          }

          if (lastSpan.state != cell.state || cell.verb.instantaneous && cell.state) {
            consolidated.push(lastSpan);
            lastSpan = cell;
          } else {
            lastSpan.end = cell.end;
            lastSpan.width = 100 * (cell.end.diff(lastSpan.start, 'seconds', true) / effectiveRange);
          }
        });

        consolidated.push(lastSpan);

        return consolidated;
      });
    }),

    trackOffset: _ember['default'].computed('windowStart', 'slicedData', 'effectiveRange', function () {
      var windowStart = this.get('windowStart'),
          firstData = this.get('slicedData.firstObject'),
          effectiveRange = this.get('effectiveRange');

      if (!firstData) {
        return;
      }

      return 100 * (firstData.start.diff(windowStart, 'seconds', true) / effectiveRange);
    }),

    cursorPercentX: _ember['default'].computed('effectiveRange', 'position', function () {
      var effectiveRange = this.get('effectiveRange'),
          position = this.get('position'),
          windowStart = this.get('windowStart');

      return 100 * (position.diff(windowStart, 'seconds', true) / effectiveRange);
    }),

    cursorStyle: _ember['default'].computed('cursorPercentX', function () {
      var percentX = this.get('cursorPercentX');
      return _ember['default'].String.htmlSafe('left: ' + percentX + '%;');
    }),

    actions: {
      selectSegment: function selectSegment(segment) {
        this.sendAction('onSelectSegment', segment);
      }
    }
  });
});