define('bc-frontend/components/manual-scoring-configuration-editor/edit-group-dialog/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    behaviorType: 'duration',
    groups: _ember['default'].A([]),
    behavior: null,

    actions: {
      addGroup: function addGroup(group) {
        if (group) {
          this.set('selectedGroup', {
            label: group
          });
        }
      },
      confirm: function confirm() {
        var behavior = this.get('behavior'),
            group = this.get('selectedGroup'),
            groupDefault = this.get('groupDefault');

        this.sendAction('onConfirm', behavior, group, groupDefault || !group.id);
      },
      cancel: function cancel() {
        this.sendAction('onClose');
      }
    }
  });
});