define('bc-frontend/components/change-password-dialog/component', ['exports', 'ember'], function (exports, _ember) {
  var inject = _ember['default'].inject;
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    ajax: inject.service(),
    routing: inject.service(),

    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',

    errorText: '',

    isPending: false,

    changePassword: function changePassword() {
      var self = this,
          ajax = this.get('ajax'),
          oldPassword = this.get('oldPassword'),
          newPassword = this.get('newPassword'),
          payload = {
        auth: {
          oldPassword: oldPassword,
          newPassword: newPassword
        }
      };

      this.set('isPending', true);
      ajax.request('/api/auth/change_password', {
        method: 'post',
        data: JSON.stringify(payload),
        contentType: 'application/json'
      }).then(function () {
        self.sendAction('onClose');
      }, function () {
        self.set('errorText', 'Please check your old password and try again.');
        self.set('isPending', false);
      });
    },

    isPopulated: _ember['default'].computed.and('oldPassword', 'newPassword', 'confirmNewPassword'),
    isMatched: _ember['default'].computed('newPassword', 'confirmNewPassword', function () {
      return this.get('newPassword') === this.get('confirmNewPassword');
    }),
    isValid: _ember['default'].computed.and('isPopulated', 'isMatched'),

    notMatched: _ember['default'].computed.not('isMatched'),
    showMismatchError: _ember['default'].computed.and('isPopulated', 'notMatched'),

    notValid: _ember['default'].computed.not('isValid'),
    notPending: _ember['default'].computed.not('isPending'),

    disableSubmission: _ember['default'].computed.or('notValid', 'isPending'),
    showError: _ember['default'].computed.and('errorText', 'notPending'),

    actions: {
      confirm: function confirm() {
        this.changePassword();
      },
      cancel: function cancel() {
        this.sendAction('onClose');
      }
    }
  });
});