define('bc-frontend/components/device-preview/bc-test/component', ['exports', 'ember', 'bc-frontend/mixins/device-preview-mixin'], function (exports, _ember, _bcFrontendMixinsDevicePreviewMixin) {
  exports['default'] = _ember['default'].Component.extend(_bcFrontendMixinsDevicePreviewMixin['default'], {
    isWaiting: true,
    lastSample: {},

    dataReceived: function dataReceived(payload) {
      this.set('isWaiting', false);
      this.set('lastSample', payload);
    }
  });
});