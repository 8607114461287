define('bc-frontend/components/schema-tile/alternation/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: null,
    ajax: _ember['default'].inject.service(),

    actions: {
      convert: function convert(clearExisting) {
        var testId = this.get('test.id'),
            ajax = this.get('ajax'),
            requestUri = '/api/tests/' + testId + '/convert_bulk';

        var payload = {
          test: {
            target: this.schema.slug,
            clearExisting: clearExisting
          }
        };

        ajax.request(requestUri, {
          method: 'post',
          data: JSON.stringify(payload),
          contentType: 'application/json'
        }).then(function () {});
      }
    }
  });
});