define('bc-frontend/components/schema-detail/header/component', ['exports', 'ember', 'bc-frontend/config/environment'], function (exports, _ember, _bcFrontendConfigEnvironment) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: null,

    ajax: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),

    title: null, // (optional) parameter
    test: null, // to be supplied as param
    dataSets: null, // to be supplied at param
    onRefresh: null, // to be supplied as param
    targetSchema: null, // to be supplied as param

    schema: _ember['default'].computed.alias('dataSets.firstObject.schema'),

    pendingDataSets: _ember['default'].computed.filter('dataSets', function (item) {
      var status = item.get('status');
      return ['queued', 'converting', 'analyzing'].includes(status);
    }),
    dataSetsCount: _ember['default'].computed.alias('dataSets.length'),
    pendingDataSetsCount: _ember['default'].computed.alias('pendingDataSets.length'),
    hasData: _ember['default'].computed.gte('dataSetsCount', 1),
    hasPendingData: _ember['default'].computed.gte('pendingDataSetsCount', 1),

    showCollectButton: _ember['default'].computed.alias('model.meta.canCollect'),

    exportLink: _ember['default'].computed('test.id', 'dataSets', 'session.session.authenticated.token', function () {
      var token = this.get('session.session.authenticated.token'),
          testId = this.get('test.id'),
          dataSetSchemaId = this.get('dataSets.firstObject.schema.id'),
          dataHost = _bcFrontendConfigEnvironment['default']['apiHost'];

      var requestObj = {
        'token': token
      };
      var q = btoa(decodeURIComponent(encodeURIComponent(JSON.stringify(requestObj))));

      return dataHost + '/api/tests/' + testId + '/csv?q=' + q + '&schema_id=' + dataSetSchemaId;
    }),

    actions: {
      generateModalClosed: function generateModalClosed() {
        this.sendAction('onRefresh');
        this.set('showGenerateData', false);
      }
    }
  });
});