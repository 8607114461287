define('bc-frontend/shared/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    sortedExperiments: _ember['default'].computed.sort('model', function (a, b) {
      var aId = parseInt(a.get('id')),
          bId = parseInt(b.get('id'));

      if (aId > bId) {
        return -1;
      } else if (aId < bId) {
        return 1;
      }

      return 0;
    })
  });
});