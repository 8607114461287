define('bc-frontend/test/ability', ['exports', 'ember-can', 'ember'], function (exports, _emberCan, _ember) {
  exports['default'] = _emberCan.Ability.extend({
    canManage: _ember['default'].computed('model.myPermissions', function () {
      var permissions = this.get('model.myPermissions');
      return permissions && permissions.indexOf('manage_test') >= 0;
    }),
    canChange: _ember['default'].computed('model.myPermissions', function () {
      var permissions = this.get('model.myPermissions');
      return permissions && permissions.indexOf('change_test') >= 0;
    }),
    canRead: _ember['default'].computed('model.myPermissions', function () {
      var permissions = this.get('model.myPermissions');
      return permissions && permissions.indexOf('read_test') >= 0;
    }),
    canDelete: _ember['default'].computed('model.myPermissions', function () {
      var permissions = this.get('model.myPermissions');
      return permissions && permissions.indexOf('delete_test') >= 0;
    })
  });
});