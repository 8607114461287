define('bc-frontend/router', ['exports', 'ember', 'bc-frontend/config/environment'], function (exports, _ember, _bcFrontendConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _bcFrontendConfigEnvironment['default'].locationType,
    rootURL: _bcFrontendConfigEnvironment['default'].rootURL,
    metrics: _ember['default'].inject.service(),

    didTransition: function didTransition() {
      this._super.apply(this, arguments);
      this._trackPage();
    },

    _trackPage: function _trackPage() {
      var _this = this;

      _ember['default'].run.scheduleOnce('afterRender', this, function () {
        var page = _this.get('url');
        var title = _this.getWithDefault('currentRouteName', 'unknown');

        _ember['default'].get(_this, 'metrics').trackPage({ page: page, title: title });
      });
    }
  });

  Router.map(function () {
    this.route('login');
    this.route('user', {
      path: '/user/:user_id'
    });
    this.route('dashboard');
    this.route('devices');
    this.route('tests', {
      path: '/patients'
    });
    this.route('device', {
      path: '/device/:device_id'
    }, function () {
      this.route('dataset', {
        path: '/dataset/:dataset_id'
      });
    });
    this.route('test', {
      path: '/test/:test_id'
    }, function () {
      this.route('alternation', {
        path: '/alternation/:schema_id'
      });
      this.route('analysis', {
        path: '/analysis/:schema_id'
      });
      this.route('boundary', {
        path: '/boundary/:schema_id'
      });
      this.route('calibration', {
        path: '/calibration/:schema_id'
      });
      this.route('centroids', {
        path: '/centroids/:schema_id'
      });
      this.route('data', {
        path: '/data/:dataset_id'
      });
      // dataset is replacing data soon...
      this.route('dataset', {
        path: '/dataset/:dataset_id'
      });
      this.route('videos', {
        path: '/videos/:schema_id'
      });
      this.route('manual-data', {
        path: '/manual-data/:schema_id'
      });
      this.route('manual-setup', {
        path: '/manual-setup/:schema_id'
      });
      this.route('manual-analysis', {
        path: '/manual-analysis/:schema_id'
      });
      this.route('zones', {
        path: '/zones/:schema_id'
      });
      this.route('zoned-analysis', {
        path: '/zoned-analysis/:schema_id'
      });
      this.route('zone-sequence', {
        path: '/zone-sequence/:schema_id'
      });
      this.route('photobeam', {
        path: '/photobeam/:schema_id'
      });
      this.route('photobeam-activity', {
        path: '/photobeam-activity/:schema_id'
      });
    });
    this.route('settings', function () {
      this.route('billing');
    });
    this.route('forgot-password');
    this.route('confirm');
    this.route('activate', {
      path: '/activate/:user_id'
    });
    this.route('activatepw', {
      path: '/activatepw/:user_id'
    });
    this.route('shared');
    this.route('loading');
  });

  exports['default'] = Router;
});