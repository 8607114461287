define('bc-frontend/components/tests/confirm-skip-dialog/component', ['exports', 'ember'], function (exports, _ember) {
  var inject = _ember['default'].inject;
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    ajax: inject.service(),
    routing: inject.service(),
    store: inject.service(),

    skipWorkflowStep: function skipWorkflowStep() {
      var self = this,
          recordId = this.get('model.id'),
          ajax = this.get('ajax'),
          requestUri = '/api/dataSets/' + recordId + '/skip';

      var payload = {
        dataSet: {}
      };

      ajax.request(requestUri, {
        method: 'post',
        data: JSON.stringify(payload),
        contentType: 'application/json'
      }).then(function () {
        self.sendAction('onClose');
      }, function () {
        alert('failed!');
      });
    },

    actions: {
      confirm: function confirm() {
        this.skipWorkflowStep();
      },
      cancel: function cancel() {
        this.sendAction('onClose');
      }
    }
  });
});