define('bc-frontend/utils/pad', ['exports', 'ember-pad/utils/pad'], function (exports, _emberPadUtilsPad) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberPadUtilsPad['default'];
    }
  });
  Object.defineProperty(exports, 'padStart', {
    enumerable: true,
    get: function get() {
      return _emberPadUtilsPad.padStart;
    }
  });
  Object.defineProperty(exports, 'padEnd', {
    enumerable: true,
    get: function get() {
      return _emberPadUtilsPad.padEnd;
    }
  });
  Object.defineProperty(exports, 'padTpl', {
    enumerable: true,
    get: function get() {
      return _emberPadUtilsPad.padTpl;
    }
  });
  Object.defineProperty(exports, 'padStartTpl', {
    enumerable: true,
    get: function get() {
      return _emberPadUtilsPad.padStartTpl;
    }
  });
  Object.defineProperty(exports, 'padEndTpl', {
    enumerable: true,
    get: function get() {
      return _emberPadUtilsPad.padEndTpl;
    }
  });
});