define('bc-frontend/test/index/controller', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  var computed = _ember['default'].computed;
  var inject = _ember['default'].inject;
  exports['default'] = _ember['default'].Controller.extend({
    ajax: inject.service(),

    testController: inject.controller('test'),
    test: computed.alias('testController.model'),
    selectedTab: 0,

    outcomesJson: computed('test', function () {
      var ajax = this.get('ajax'),
          testId = this.get('test.id');

      return _emberData['default'].PromiseArray.create({
        promise: ajax.request('/api/tests/' + testId + '/json?schema_id=23')
      });
    }),

    runsJson: computed('test', function () {
      var ajax = this.get('ajax'),
          testId = this.get('test.id');

      return _emberData['default'].PromiseArray.create({
        promise: ajax.request('/api/tests/' + testId + '/json?schema_id=21')
      });
    }),

    outcomesFormatted: computed('outcomesJson.isFulfilled', function () {
      var data = this.get('outcomesJson'),
          mappedData = _ember['default'].A([]);

      if (!data.get('isFulfilled')) return [];

      data.forEach(function (row) {
        var keys = Object.getOwnPropertyNames(row);
        keys.forEach(function (key) {
          if (key != 't' && key != 'stage') {
            mappedData.pushObject({
              time: new Date(row.t),
              value: row[key],
              label: key
            });
          }
        });
      });

      return mappedData;
    }),

    activityFormatted: computed('outcomesJson.isFulfilled', 'runsJson.isFulfilled', function () {
      var outcomesData = this.get('outcomesJson'),
          runsData = this.get('runsJson'),
          mappedData = _ember['default'].A([]);

      if (!outcomesData.get('isFulfilled') || !runsData.get('isFulfilled')) return [];

      outcomesData.forEach(function (row) {
        mappedData.pushObject({
          time: new Date(row.t),
          label: 'Diary entry',
          value: 1,
          icon: 'insert_comment'
        });
      });

      runsData.forEach(function (row) {
        mappedData.pushObject({
          time: new Date(row.t),
          label: 'Device run',
          value: 1,
          icon: 'directions_run'
        });
      });

      return mappedData;
    }),

    activityReady: computed('outcomesJson.isFulfilled', 'runsJson.isFulfilled', function () {
      return this.get('outcomesJson.isFulfilled') && this.get('runsJson.isFulfilled');
    }),

    activitySorting: ['time:desc'],
    sortedActivity: _ember['default'].computed.sort('activityFormatted', 'activitySorting')
  });
});