define('bc-frontend/test/manual-data/controller', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var inject = _ember['default'].inject;
  exports['default'] = _ember['default'].Controller.extend({
    ajax: inject.service(),
    session: inject.service(),
    pusher: _ember['default'].inject.service(),

    testController: inject.controller('test'),
    test: computed.alias('testController.model'),

    dataSets: computed.alias('model'),
    dataSetsCount: computed.alias('dataSets.length'),
    hasData: computed.gte('dataSetsCount', 1),

    showCollectButton: computed.alias('model.meta.canCollect'),

    actions: {
      generate: function generate(clearExisting) {
        var self = this,
            testId = this.get('test.id'),
            ajax = this.get('ajax'),
            requestUri = '/api/tests/' + testId + '/convert_bulk';

        var payload = {
          test: {
            source: 'video',
            target: 'manual-scoring-data',
            clearExisting: clearExisting
          }
        };

        ajax.request(requestUri, {
          method: 'post',
          data: JSON.stringify(payload),
          contentType: 'application/json'
        }).then(function () {
          self.get('target.router').refresh();
        });
      }
    }
  });
});