define('bc-frontend/components/tests/create-test-dialog/component', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  var inject = _ember['default'].inject;
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    store: inject.service(),
    routing: inject.service(),

    label: '',
    name: '',
    age: '',
    liveTest: true,
    selectedSchema: _ember['default'].computed.reads('testSchemas.firstObject'),

    showOptions: _ember['default'].computed.and('selectedSchema.supportsUpload', 'selectedSchema.supportsLive'),

    testSchemas: _ember['default'].computed(function () {
      return _emberData['default'].PromiseArray.create({
        promise: this.get('store').findAll('testSchema')
      });
    }),

    restrictLiveTest: _ember['default'].observer('selectedSchema', function () {
      var selectedSchema = this.get('selectedSchema'),
          liveTest = this.get('liveTest');

      if (liveTest && !selectedSchema.get('supportsLive')) {
        this.set('liveTest', false);
      } else if (!liveTest && !selectedSchema.get('supportsUpload')) {
        this.set('liveTest', true);
      }
    }),

    createExperiment: function createExperiment() {
      var self = this;

      var record = this.get('store').createRecord('test', {
        label: this.get('label'),
        name: this.get('name'),
        schema: this.get('selectedSchema'),
        liveTest: this.get('liveTest')

      });

      record.save().then(function () {
        self.sendAction('onClose');
        self.get('routing').transitionTo('test', record);
      });
    },

    actions: {
      confirm: function confirm() {
        this.createExperiment();
      },
      cancel: function cancel() {
        this.sendAction('onClose');
      }
    }
  });
});