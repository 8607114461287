define('bc-frontend/device/dataset/controller', ['exports', 'ember', 'bc-frontend/config/environment'], function (exports, _ember, _bcFrontendConfigEnvironment) {
  exports['default'] = _ember['default'].Controller.extend({
    session: _ember['default'].inject.service(),

    isAborted: _ember['default'].computed.equal('model.status', 'aborted'),
    isCompleted: _ember['default'].computed.equal('model.status', 'completed'),
    hasError: _ember['default'].computed.equal('model.status', 'error'),

    shouldShowDownload: _ember['default'].computed.and('model.canExport', 'isCompleted'),
    modelChanged: false,
    preventTransition: _ember['default'].computed.alias('modelChanged'),

    exportLink: _ember['default'].computed('model', 'session.session.authenticated.token', function () {
      var token = this.get('session.session.authenticated.token'),
          dataSetId = this.get('model.id'),
          dataHost = _bcFrontendConfigEnvironment['default']['apiHost'];

      var requestObj = {
        'token': token
      };
      var q = btoa(decodeURIComponent(encodeURIComponent(JSON.stringify(requestObj))));

      return dataHost + '/api/dataSets/' + dataSetId + '/csv?q=' + q;
    }),

    onAppend: function onAppend(data) {
      var model = this.get('model');

      if (model.isRealtime) {
        model.set('lastSample', data.payload);
      }
    },

    actions: {
      transition: function transition(route) {
        this.transitionToRoute(route);
      }
    }
  });
});