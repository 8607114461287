define('bc-frontend/test/data/route', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'bc-frontend/mixins/data-set-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _bcFrontendMixinsDataSetRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], _bcFrontendMixinsDataSetRouteMixin['default'], {
    model: function model(params) {
      return this.get('store').findRecord('dataSet', params.dataset_id);
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.initialize();
    }
  });
});