define('bc-frontend/test/model', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _emberDataModel, _emberDataAttr, _emberDataRelationships) {
  exports['default'] = _emberDataModel['default'].extend({
    created: (0, _emberDataAttr['default'])('date'),
    deletedAt: (0, _emberDataAttr['default'])('date'),
    updated: (0, _emberDataAttr['default'])('date'),
    user: (0, _emberDataRelationships.belongsTo)('user'),

    name: (0, _emberDataAttr['default'])('string'),
    label: (0, _emberDataAttr['default'])('string'),
    subjectCount: (0, _emberDataAttr['default'])('number'),
    hasCollaborators: (0, _emberDataAttr['default'])('boolean'),
    myPermissions: (0, _emberDataAttr['default'])(),

    schema: (0, _emberDataRelationships.belongsTo)('testSchema'),
    liveTest: (0, _emberDataAttr['default'])('boolean'),
    supportsMultichamber: (0, _emberDataAttr['default'])('boolean'),
    metaData: (0, _emberDataAttr['default'])()
  });
});