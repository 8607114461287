define('bc-frontend/components/schema-tile/zoned-analysis/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: null,

    actions: {
      generateModalClosed: function generateModalClosed() {
        this.set('showGenerateAnalysis', false);
      },
      regenerateModalClosed: function regenerateModalClosed() {
        this.set('showRegenerateAnalysis', false);
      }
    }
  });
});