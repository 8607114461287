define('bc-frontend/initializers/ember-intercom', ['exports', 'ember-intercom-io/initializers/ember-intercom'], function (exports, _emberIntercomIoInitializersEmberIntercom) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberIntercomIoInitializersEmberIntercom['default'];
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function get() {
      return _emberIntercomIoInitializersEmberIntercom.initialize;
    }
  });
});