define('bc-frontend/components/tests/capture-bulk-dialog/component', ['exports', 'ember', 'bc-frontend/data-set/modern-schemas'], function (exports, _ember, _bcFrontendDataSetModernSchemas) {
  var computed = _ember['default'].computed;
  var inject = _ember['default'].inject;
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    ajax: inject.service(),
    routing: inject.service(),
    store: inject.service(),
    userSession: inject.service(),

    next: false, // override to show "next subject" message

    devices: computed('schema', function () {
      var schema = this.get('schema');
      // return this.get('store').findAll('device');
      return this.get('store').query('dataSet', { realtime: true, schema: schema });
    }),

    stations: computed('schema', 'test', 'updateStamp', function () {
      var self = this,
          ajax = this.get('ajax'),
          schema = this.get('schema'),
          test = this.get('test'),
          testId = test.get('id'),
          updateStamp = this.get('updateStamp'),
          requestUri = '/api/tests/' + testId + '/available_stations?schema=' + schema;

      // return this.get('store').findAll('device');
      // return this.get('store').query('dataSet', {realtime: true, schema});

      var req = ajax.request(requestUri).then(function (response) {
        // pull this out while we're at it.
        self.set('lastDuration', response.test.lastDuration);

        return response.test.stations;
      }, function () {
        alert("Failed to retrieve available stations. Please try again later.");
      });

      return DS.PromiseArray.create({
        promise: req
      });
    }),

    selectedStations: computed('stations', 'stations.isFulfilled', function () {
      var stations = this.get('stations');
      if (!stations.get('isFulfilled')) return _ember['default'].A([]);

      return stations.filter(function (station) {
        return !!station.lastSubject;
      });
    }),

    availableStations: computed('stations', 'stations.isFulfilled', 'selectedStations', 'selectedStations.length', function () {
      var stations = this.get('stations'),
          selectedIds = this.get('selectedStations').mapBy('id');

      return stations.filter(function (station) {
        return !selectedIds.includes(station.id);
      });
    }),

    device: null,
    duration: computed.reads('lastDuration'),

    // to be injected
    schema: null,

    schemaModel: computed('schema', function () {
      return this.get('store').peekRecord('dataSetSchema', this.get('schema'));
    }),

    showUpgradePrompt: false,

    subjects: computed.mapBy('selectedStations', 'subject'),
    validated: computed('duration', 'subjects', function () {
      var duration = this.get('duration'),
          subjects = this.get('subjects');

      if (!duration || subjects.get('length') <= 0) return false;

      return subjects.every(function (item) {
        return !!item;
      });
    }),

    checkOverage: function checkOverage() {
      var self = this,
          ajax = this.get('ajax'),
          subjectCount = this.get('subjects.length'),
          duration = this.get('duration') * subjectCount,
          userId = this.get('userSession.userId');

      ajax.request('/api/users/' + userId + '/get_overage_cost?time=' + duration).then(function (response) {
        var upgradeNeeded = response.user.upgradeNeeded;

        if (upgradeNeeded) {
          self.set('showUpgradePrompt', true);
        } else {
          self.createAndCaptureDataSets();
        }
      }, function (error) {
        if (error.errors[0].status === "400") {
          self.set('showUpgradePrompt', true);
          return;
        }
        alert("We were unable to communicate with the server. Please try again momentarily.");
      });
    },

    processStripeToken: function processStripeToken(token) {
      var self = this,
          ajax = this.get('ajax'),
          userId = this.get('userSession.userId'),
          url = '/api/users/' + userId + '/create_card',
          payload = {
        user: {
          card: token.id
        }
      };

      ajax.request(url, {
        method: 'post',
        contentType: 'application/json',
        data: JSON.stringify(payload)
      }).then(function () {
        self.createAndCaptureDataSets();
      }, function () {
        alert("Failed to subscribe. Please try again shortly.");
      });
    },

    createAndCaptureDataSets: function createAndCaptureDataSets() {
      var self = this,
          selectedStations = this.get('selectedStations'),
          duration = this.get('duration'),
          ajax = this.get('ajax'),
          requestUri = '/api/dataSets/bulk_observe';

      var payload = {
        dataSet: {
          test: self.get('test.id'),
          capture: true,
          stations: selectedStations.map(function (station) {
            return {
              id: station.id,
              subject: station.subject,
              settings: {
                duration: duration
              }
            };
          })
        }
      };

      ajax.request(requestUri, {
        method: 'post',
        data: JSON.stringify(payload),
        contentType: 'application/json'
      }).then(function (recordJson) {
        self.get('store').pushPayload(recordJson);
        self.sendAction('onClose', true);
      }, function () {
        alert('failed!');
      });
    },

    captureDataSet: function captureDataSet(record) {
      var self = this,
          recordId = record.get('id'),
          ajax = this.get('ajax'),
          requestUri = '/api/dataSets/' + recordId + '/capture';

      var payload = {
        dataSet: {
          settings: {
            duration: this.get('duration')
          }
        }
      };

      ajax.request(requestUri, {
        method: 'post',
        data: JSON.stringify(payload),
        contentType: 'application/json'
      }).then(function () {
        self.sendAction('onClose');
        if (_bcFrontendDataSetModernSchemas['default'].includes(self.get('schemaModel.slug'))) {
          self.get('routing').transitionTo('test.dataset', record);
        } else {
          self.get('routing').transitionTo('test.data', record);
        }
      }, function () {
        alert('failed!');
      });
    },

    deviceLabelCallback: function deviceLabelCallback(model) {
      return model.get('label');
    },

    selectStation: function selectStation(station) {
      var selectedStations = this.get('selectedStations');

      selectedStations.pushObject(station);
    },

    deselectStation: function deselectStation(station) {
      var selectedStations = this.get('selectedStations');

      selectedStations.removeObject(station);
    },

    actions: {
      addStation: function addStation(station) {
        this.selectStation(station);
      },
      removeStation: function removeStation(station) {
        this.deselectStation(station);
      },
      confirm: function confirm() {
        //this.createDataSet();
        this.checkOverage();
      },
      cancel: function cancel() {
        this.sendAction('onClose');
      },
      processStripeToken: function processStripeToken(token) {
        this.processStripeToken(token);
      }
    }
  });
});