define('bc-frontend/activatepw/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    queryParams: {
      token: {
        refreshModel: true
      }
    },

    model: function model(params) {
      var user_id = params.user_id;
      var token = params.token;

      return {
        user_id: user_id,
        token: token
      };
    }
  });
});