define('bc-frontend/initializers/clock', ['exports', 'ember-clock/initializers/clock'], function (exports, _emberClockInitializersClock) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberClockInitializersClock['default'];
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function get() {
      return _emberClockInitializersClock.initialize;
    }
  });
});