define('bc-frontend/components/dataset-detail/components/table/component', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  exports['default'] = _ember['default'].Component.extend({
    ajaxNoAuth: _ember['default'].inject.service(),

    model: null,
    url: _ember['default'].computed.reads('model.url'),

    dataRows: _ember['default'].computed(function () {
      var ajax = this.get('ajaxNoAuth'),
          url = this.get('url');

      if (!url) {
        return _ember['default'].A([]);
      }

      var ajaxPromise = ajax.request(url).then(function (result) {
        return result;
      }, function () {
        return _ember['default'].A([]);
      });

      return _emberData['default'].PromiseArray.create({
        promise: ajaxPromise
      });
    })
  });
});