define('bc-frontend/tests/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    queryParams: ['includeArchived', 'searchParam'],
    includeArchived: false,
    searchParam: '',

    sortedExperiments: _ember['default'].computed.sort('model', function (a, b) {
      var aId = parseInt(a.get('id')),
          bId = parseInt(b.get('id'));

      if (aId > bId) {
        return -1;
      } else if (aId < bId) {
        return 1;
      }

      return 0;
    }),

    setSearchInput: function setSearchInput(input) {
      this.set('searchParam', input);
    },

    actions: {
      setSearchInput: function setSearchInput(input) {
        this.set('searchInput', input);
        _ember['default'].run.debounce(this, this.setSearchInput, input, 500);
      }
    }
  });
});