define('bc-frontend/initializers/key-manager-config', ['exports', 'bc-frontend/config/environment', 'ember'], function (exports, _bcFrontendConfigEnvironment, _ember) {
  exports.initialize = initialize;
  var get = _ember['default'].get;

  function initialize(application) {
    var keyManagerConfig = get(_bcFrontendConfigEnvironment['default'], 'keyManagerConfig') || {};
    application.register('main:key-manager-config', keyManagerConfig, {
      instantiate: false
    });
  }

  exports['default'] = {
    name: 'key-manager-config',
    initialize: initialize
  };
});