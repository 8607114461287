define('bc-frontend/components/collaborator-tile/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    userSession: _ember['default'].inject.service(),

    displayPerm: _ember['default'].computed('collaborator.permissions', function () {
      var permissions = this.get('collaborator.permissions');

      if (permissions.indexOf('delete_test') >= 0 || permissions.indexOf('manage_test') >= 0) {
        return 'Admin';
      } else if (permissions.indexOf('change_test') >= 0) {
        return 'Read/Write';
      } else {
        return 'Read Only';
      }
    }),

    isMe: _ember['default'].computed('userSession.userId', 'collaborator.id', function () {
      var userId = this.get('userSession.userId'),
          collaboratorId = this.get('collaborator.id');

      return userId == collaboratorId;
    }),

    actions: {
      setPermission: function setPermission(permission) {
        var collaborator = this.get('collaborator');
        this.sendAction('onChange', collaborator, permission);
      },
      removeCollaborator: function removeCollaborator() {
        var collaborator = this.get('collaborator');
        this.sendAction('onRemove', collaborator);
      }
    }
  });
});