define('bc-frontend/services/raven', ['exports', 'ember-cli-sentry/services/raven'], function (exports, _emberCliSentryServicesRaven) {
  exports['default'] = _emberCliSentryServicesRaven['default'].extend({

    unhandledPromiseErrorMessage: '',

    captureException: function captureException() /* error */{
      this._super.apply(this, arguments);
    },

    captureMessage: function captureMessage() /* message */{
      return this._super.apply(this, arguments);
    },

    enableGlobalErrorCatching: function enableGlobalErrorCatching() {
      return this._super.apply(this, arguments);
    },

    ignoreError: function ignoreError() {
      return this._super();
    },

    callRaven: function callRaven() /* methodName, ...optional */{
      return this._super.apply(this, arguments);
    }
  });
});