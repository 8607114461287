define('bc-frontend/components/video-calibration/empty-state/component', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Component.extend({
    isRecorded: computed.alias('test.liveTest'),
    isUploaded: computed.not('isRecorded'),

    needsVideo: computed.not('model.meta.backgroundUri'),

    actions: {
      startCalibration: function startCalibration() {
        this.sendAction('onAutoCalibrate');
      },
      showManualCalibration: function showManualCalibration() {
        this.sendAction('onManualCalibrate');
      }
    }
  });
});