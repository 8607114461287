define('bc-frontend/components/devices/add-device-dialog/component', ['exports', 'ember'], function (exports, _ember) {
  var inject = _ember['default'].inject;
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    ajax: inject.service(),
    store: inject.service(),

    //selectedVendor: null,
    selectedVendor: 'behaviorcloud',
    deviceId: '',
    claimKey: '',

    // deviceVendors: Ember.computed(function() {
    //   return DS.PromiseArray.create({
    //     promise: this.get('store').findAll('vendor')
    //   });
    // }),

    showCamera: false,
    showBridge: false,
    typeSelected: _ember['default'].computed.or('showCamera', 'showBridge'),

    isPending: false,

    makeClaim: function makeClaim() {
      var self = this,
          vendorSlug = this.get('selectedVendor'),
          deviceId = this.get('deviceId'),
          claimKey = this.get('claimKey'),
          ajax = this.get('ajax'),
          requestUri = '/api/devices/claim';

      var payload = {
        device: {
          vendor: vendorSlug,
          deviceId: deviceId,
          signature: claimKey
        }
      };

      ajax.request(requestUri, {
        method: 'post',
        data: JSON.stringify(payload),
        contentType: 'application/json'
      }).then(function (recordJson) {
        self.sendAction('onClose');
      }, function (error, more) {
        alert('Failed to find your device. Please check your entries and try again.');
      });
    },

    actions: {
      cancel: function cancel() {
        this.sendAction('onClose');
      },
      claimDevice: function claimDevice() {
        this.makeClaim();
      }
    }
  });
});