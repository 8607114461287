define('bc-frontend/components/manual-scoring-configuration-editor/edit-behavior-dialog/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    label: _ember['default'].computed.reads('behavior.label'),

    actions: {
      confirm: function confirm() {
        var behavior = this.get('behavior'),
            label = this.get('label');

        this.sendAction('onUpdate', behavior, label);
      },
      cancel: function cancel() {
        this.sendAction('onClose');
      }
    }
  });
});