define('bc-frontend/components/verb-chart/segment-spacer/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNameBindings: [':segment'],
    attributeBindings: ['style'],

    style: _ember['default'].computed('segment.width', function () {
      var width = this.get('segment.width') * 3 / 4;
      return _ember['default'].String.htmlSafe('width: ' + width + '%;');
    })
  });
});