define('bc-frontend/components/dataset-tile/component', ['exports', 'ember', 'bc-frontend/data-set/modern-schemas'], function (exports, _ember, _bcFrontendDataSetModernSchemas) {

  var STATUS_MAP = {
    aborted: {
      display: 'Aborted by User',
      icon: 'stop'
    },
    calibrated: {
      display: 'Running',
      icon: 'play_arrow'
    },
    cleared: {
      display: 'Please clear arena',
      icon: 'border_clear',
      showRealtimeId: true
    },
    clearing: {
      display: 'Please clear arena',
      icon: 'border_clear',
      showRealtimeId: true
    },
    collecting: {
      display: 'Running',
      icon: 'play_arrow',
      showRealtimeId: true
    },
    completed: {
      display: 'Completed',
      icon: 'done',
      showTime: true
    },
    converting: {
      display: 'Analyzing',
      showProgress: true
    },
    error: {
      display: 'Error',
      icon: 'close'
    },
    inserted: {
      display: 'Please insert subject',
      icon: 'input',
      showRealtimeId: true
    },
    inserting: {
      display: 'Please insert subject',
      icon: 'input',
      showRealtimeId: true
    },
    queued: {
      display: 'Queued',
      icon: 'pause'
    },
    append: {
      display: 'Ready for input',
      icon: 'input'
    },
    uploading: {
      display: 'Uploading',
      icon: 'file_upload'
    },
    meta: {
      display: 'Awaiting user input',
      icon: 'touch_app'
    },
    'default': {
      display: 'Queued',
      icon: 'pause'
    }
  };

  exports['default'] = _ember['default'].Component.extend({
    model: null,
    routing: _ember['default'].inject.service(),

    statusMapping: _ember['default'].computed('model.status', function () {
      var status = this.get('model.status');

      if (!STATUS_MAP.hasOwnProperty(status)) {
        return STATUS_MAP['default'];
      }
      return STATUS_MAP[status];
    }),

    schema: _ember['default'].computed.alias('model.schema'),
    schemaSlug: _ember['default'].computed.alias('schema.slug'),

    route: _ember['default'].computed('schemaSlug', function () {
      var slug = this.get('schemaSlug'),
          isRealtime = this.get('model.isRealtime');

      if (slug === undefined) {
        return null;
      }

      if (isRealtime) {
        return 'device.dataset';
      }
      if (_bcFrontendDataSetModernSchemas['default'].includes(slug)) {
        return 'test.dataset';
      }
      return 'test.data';
    }),

    actions: {
      viewModel: function viewModel() {
        var route = this.get('route'),
            model = this.get('model');
        if (route) {
          this.get('routing').transitionTo(route, model);
        }
      }
    }
  });
});