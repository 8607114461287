define('bc-frontend/components/billing/confirm-cancel-dialog/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    actions: {
      confirm: function confirm() {
        this.sendAction('onConfirm');
      },
      cancel: function cancel() {
        this.sendAction('onClose');
      }
    }
  });
});