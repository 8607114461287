define('bc-frontend/login/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    session: _ember['default'].inject.service(),

    isPending: false,
    errorMessage: '',

    actions: {
      authenticate: function authenticate() {
        var _this = this;

        var credentials = this.getProperties('identification', 'password'),
            authenticator = 'authenticator:jwt';

        this.set('isPending', true);
        this.get('session').authenticate(authenticator, credentials)['catch'](function (reason) {
          _this.set('errorMessage', reason.non_field_errors || reason.error || reason);
          _this.set('isPending', false);
        });
      }
    }
  });
});
// app/controllers/login.js