define('bc-frontend/helpers/format-date', ['exports', 'ember-string-helpers/helpers/format-date'], function (exports, _emberStringHelpersHelpersFormatDate) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberStringHelpersHelpersFormatDate['default'];
    }
  });
  Object.defineProperty(exports, 'formatDate', {
    enumerable: true,
    get: function get() {
      return _emberStringHelpersHelpersFormatDate.formatDate;
    }
  });
});