define('bc-frontend/components/jw-player/component', ['exports', 'ember', 'bc-frontend/config/environment'], function (exports, _ember, _bcFrontendConfigEnvironment) {

  var STREAMING_BASE = _bcFrontendConfigEnvironment['default']['streaming-base'];

  exports['default'] = _ember['default'].Component.extend({
    startStreaming: function startStreaming() {
      // let deviceId = this.get('model.id'),
      var deviceUUID = this.get('model.id'),
          devicePath = STREAMING_BASE + '/' + deviceUUID;

      this.startPlayer(devicePath);
    },

    startPlayer: function startPlayer(stream) {

      jwplayer('jw-player').setup({
        height: 480,
        width: "100%",
        // sources: [{
        //   file: stream
        // }],
        file: stream,
        rtmp: {
          bufferlength: 0.1
        }
      });

      jwplayer("jw-player").onMeta(function () {
        // var info = "";
        // for (var key in data) {
        //     info += key + " = " + data[key] + "<BR>";
        // }
        // document.getElementById("status").innerHTML = info;

        // print("event",event);
      });

      jwplayer('jw-player').play();
    },

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.startStreaming();
    }
  });
});
/* globals jwplayer */