define('bc-frontend/helpers/pad-start', ['exports', 'ember-pad/helpers/pad-start'], function (exports, _emberPadHelpersPadStart) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberPadHelpersPadStart['default'];
    }
  });
  Object.defineProperty(exports, 'padStart', {
    enumerable: true,
    get: function get() {
      return _emberPadHelpersPadStart.padStart;
    }
  });
});