define('bc-frontend/components/verb-chart/verb/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: '',

    style: _ember['default'].computed('offset', function () {
      var offset = this.get('offset');
      return _ember['default'].String.htmlSafe('left: ' + offset + '%;');
    }),

    actions: {
      selectSegment: function selectSegment(segment) {
        this.sendAction('onSelectSegment', segment);
      }
    }
  });
});