define('bc-frontend/components/manual-scoring-configuration-editor/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    ajaxNoAuth: _ember['default'].inject.service(),

    tagName: 'div',
    existingConfiguration: null,
    // have to do wonky computed workaround to initialize model to an object
    // or else all instances of the editor component will share the same singleton
    model: _ember['default'].computed(function () {
      return _ember['default'].Object.create({
        subjects: [],
        verbGroup: [],
        verbs: []
      });
    }),
    subjectIndex: 0,
    verbIndex: 0,
    verbGroupIndex: 0,

    disabled: false,

    _setup: (function () {
      var _this = this;

      _ember['default'].run.scheduleOnce('afterRender', function () {
        _this.loadConfiguration();
        _this.registerWithParent();
      });
    }).on('didInsertElement'),

    exportData: function exportData() {
      var _get = this.get('model');

      var subjects = _get.subjects;
      var verbGroup = _get.verbGroup;
      var verbs = _get.verbs;

      // We can generally export the data as-is EXCEPT for the group object
      // reference
      return {
        subjects: subjects,
        verbGroup: verbGroup,
        verbs: verbs.map(function (_ref) {
          var groupId = _ref.groupId;
          var groupDefault = _ref.groupDefault;
          var id = _ref.id;
          var instantaneous = _ref.instantaneous;
          var key = _ref.key;
          var label = _ref.label;
          return {
            groupId: groupId,
            groupDefault: groupDefault,
            id: id,
            instantaneous: instantaneous,
            key: key,
            label: label
          };
        })
      };
    },

    loadConfiguration: function loadConfiguration() {
      var self = this,
          ajax = this.get('ajaxNoAuth'),
          url = this.get('existingConfiguration');

      if (url) {
        ajax.request(url).then(function (result) {
          var groupMap = {};

          var model = _ember['default'].Object.create({
            subjects: [],
            verbGroup: result.verbGroup.map(function (group) {
              return _ember['default'].Object.create(group);
            }),
            verbs: result.verbs.map(function (verb) {
              return _ember['default'].Object.create(verb);
            })
          });

          var maxVerbIndex = 0,
              maxGroupIndex = 0;

          model.verbGroup.forEach(function (group) {
            groupMap[group.id] = group;
            maxGroupIndex = Math.max(maxGroupIndex, group.id);
          });
          model.verbs.forEach(function (verb) {
            verb.set('group', groupMap[verb.groupId]);
            maxVerbIndex = Math.max(maxVerbIndex, verb.id);
          });

          self.set('model', model);
          self.set('verbIndex', maxVerbIndex);
          self.set('verbGroupIndex', maxGroupIndex);
        });
      }
    },

    registerWithParent: function registerWithParent() {
      this.sendAction('onRegister', this);
    },

    addBehavior: function addBehavior(label, behaviorType) {
      var model = this.get('model'),
          id = this.get('verbIndex') + 1,
          key = label.charAt(0).toLowerCase();

      if (model.verbs.filter(function (verb) {
        return verb.key === key;
      }).length > 0) {
        key = null;
      }

      this.set('verbIndex', id);

      model.verbs.pushObject(_ember['default'].Object.create({
        id: id,
        label: label,
        instantaneous: behaviorType === 'count',
        groupId: null,
        group: null,
        key: key
      }));

      this.sendAction('onChange');
    },

    updateBehavior: function updateBehavior(behavior, label) {
      behavior.set('label', label);

      this.sendAction('onChange');
    },

    setShortcut: function setShortcut(behavior, shortcut) {
      if (shortcut) {
        behavior.set('key', shortcut.charAt(0).toLowerCase());
      } else {
        behavior.set('key', null);
      }

      this.sendAction('onChange');
    },

    setGroup: function setGroup(behavior, group, groupDefault) {
      var model = this.get('model');

      // add group if it doesn't yet exist
      if (model.verbGroup.indexOf(group) < 0) {
        group.id = this.get('verbGroupIndex') + 1;
        this.set('verbGroupIndex', group.id);
        model.verbGroup.pushObject(group);
      }

      // clear other verbs as default if this one is
      // assuming the position
      if (groupDefault) {
        model.verbs.forEach(function (verb) {
          if (verb.groupId == group.id) {
            verb.set('groupDefault', false);
          }
        });
      }

      behavior.set('groupId', group.id);
      behavior.set('groupDefault', groupDefault);
      behavior.set('group', group);

      this.sendAction('onChange');
    },

    clearGroup: function clearGroup(behavior) {
      var groupId = behavior.groupId;
      var group = behavior.group;

      behavior.set('groupId', null);
      behavior.set('group', null);

      this.checkGroup(groupId, group);

      this.sendAction('onChange');
    },

    checkGroup: function checkGroup(groupId, group) {
      // depending on whether there are other verbs with this group,
      // set another verb to default or delete the group entirely.
      var model = this.get('model');
      var keep = false;

      model.verbs.forEach(function (verb) {
        if (verb.groupId === groupId) {
          if (!keep) {
            verb.set('groupDefault', true);
            keep = true;
          }
        }
      });

      if (!keep) {
        model.verbGroup.removeObject(group);
      }
    },

    setDefault: function setDefault(behavior) {
      var model = this.get('model');

      model.verbs.forEach(function (verb) {
        if (verb.groupId === behavior.group.id) {
          verb.set('groupDefault', false);
        }
      });

      behavior.set('groupDefault', true);

      this.sendAction('onChange');
    },

    deleteBehavior: function deleteBehavior(behavior) {
      var groupId = behavior.groupId;
      var group = behavior.group;

      var model = this.get('model');

      model.verbs.removeObject(behavior);

      this.checkGroup(groupId, group);

      this.sendAction('onChange');
    },

    actions: {
      saveConfiguration: function saveConfiguration() {},
      addBehavior: function addBehavior(label, behaviorType) {
        this.addBehavior(label, behaviorType);
        // Hide the modal
        this.set('showNewBehavior', false);
      },
      updateBehavior: function updateBehavior(behavior, label) {
        this.updateBehavior(behavior, label);
        // Hide the modal
        this.set('showEditBehavior', null);
      },
      setShortcut: function setShortcut(behavior, shortcut) {
        this.setShortcut(behavior, shortcut);
        // hide the modal
        this.set('showEditShortcut', null);
      },
      setGroup: function setGroup(behavior, group, groupDefault) {
        this.setGroup(behavior, group, groupDefault);
        // hide the modal
        this.set('showEditGroup', null);
      },
      clearGroup: function clearGroup(behavior) {
        this.clearGroup(behavior);
      },
      setDefault: function setDefault(behavior) {
        this.setDefault(behavior);
      },
      deleteBehavior: function deleteBehavior(behavior) {
        this.deleteBehavior(behavior);
      }
    }
  });
});