define('bc-frontend/components/video-calibration/manual/component', ['exports', 'ember'], function (exports, _ember) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  //const DEFAULT_COORDS = [220, 240, 420, 240];
  var DEFAULT_OFFSETS = [-100, 0, 100, 0];

  exports['default'] = _ember['default'].Component.extend({
    tagName: 'div',
    classNames: ['zonemap-editor'],

    ajax: _ember['default'].inject.service(),
    ajaxNoAuth: _ember['default'].inject.service(),

    backgroundUri: null,
    model: null,

    existingData: _ember['default'].computed.reads('model.url'),
    disabled: false,

    fabricCanvas: null,
    referenceSize: null,

    shapes: _ember['default'].computed(function () {
      return _ember['default'].A([]);
    }),

    _setup: (function () {
      var _this = this;

      _ember['default'].run.scheduleOnce('afterRender', function () {
        _this._setupFabric();
        _this.loadBackground();
        _this.registerWithParent();
      });
    }).on('didInsertElement'),

    _setupFabric: function _setupFabric() {
      if (_ember['default'].isEmpty(this.get('fabricCanvas'))) {
        var canvas = new window.fabric.Canvas(this.$('canvas')[0], {
          selection: false
        });
        canvas.setDimensions({ width: null, height: null }, { cssOnly: true });
        canvas.setDimensions({ width: 640, height: 480 }, { backstoreOnly: true });

        canvas.centeredScaling = false;
        canvas.renderOnAddRemove = false;
        this.set('fabricCanvas', canvas);
      }
    },

    _mapUpdated: _ember['default'].computed('existingData', function () {
      var existingMap = this.get('existingData'),
          fabricCanvas = this.get('fabricCanvas');

      if (existingMap && fabricCanvas) {
        this.loadMap();
      }
    }),

    loadBackground: function loadBackground() {
      var fabricCanvas = this.get('fabricCanvas'),
          backgroundUri = this.get('backgroundUri');

      if (!fabricCanvas || !backgroundUri) {
        return;
      }

      fabricCanvas.setBackgroundImage(backgroundUri, this.afterBackground.bind(this, fabricCanvas), {
        // Needed to position backgroundImage at 0/0
        originX: 'left',
        originY: 'top'
      });
    },

    // width: fabricCanvas.width,
    // height: fabricCanvas.height,
    afterBackground: function afterBackground(canvas) {
      canvas.setDimensions({
        width: canvas.backgroundImage.width,
        height: canvas.backgroundImage.height
      }, { backstoreOnly: true });
      canvas.renderAll();
      this.loadMap();
    },

    makeCircle: function makeCircle(id, left, top, line1, line2) {
      var c = new fabric.Circle({
        left: left - 12,
        top: top - 12,
        strokeWidth: 5,
        radius: 12,
        fill: null,
        stroke: '#3f51b5',
        id: id
      });
      c.hasControls = c.hasBorders = false;

      c.line1 = line1;
      c.line2 = line2;

      return c;
    },

    makeLine: function makeLine(coords) {
      return new fabric.Line(coords, {
        fill: 'red',
        stroke: 'red',
        strokeWidth: 5,
        selectable: false
      });
    },

    loadMap: function loadMap() {
      var self = this,
          data = this.get('existingData'),
          fabricCanvas = this.get('fabricCanvas'),
          ajax = this.get('ajaxNoAuth'),
          centerX = fabricCanvas.width / 2,
          centerY = fabricCanvas.height / 2;

      if (!fabricCanvas) {
        return;
      }

      if (data) {
        // load data
        ajax.request(data).then(function (jsonData) {
          var coords = jsonData.coords;
          var size = jsonData.size;

          self.set('referenceSize', size);
          self.buildMap.apply(self, _toConsumableArray(coords));
          self.addObservers();
        });
      } else {
        //self.buildMap(...DEFAULT_COORDS);
        self.buildMap(centerX + DEFAULT_OFFSETS[0], centerY + DEFAULT_OFFSETS[1], centerX + DEFAULT_OFFSETS[2], centerY + DEFAULT_OFFSETS[3]);
        self.addObservers();
      }
    },

    buildMap: function buildMap(x1, y1, x2, y2) {
      var fabricCanvas = this.get('fabricCanvas');

      var line = this.makeLine([x1, y1, x2, y2]);

      fabricCanvas.add(line);

      var shapes = this.get('shapes');
      shapes.pushObject(this.makeCircle('c1', line.get('x1'), line.get('y1'), null, line));
      shapes.pushObject(this.makeCircle('c2', line.get('x2'), line.get('y2'), line, null));

      fabricCanvas.add.apply(fabricCanvas, _toConsumableArray(shapes));

      fabricCanvas.renderAll();
    },

    resetMap: function resetMap() {
      var shapes = this.get('shapes'),
          fabricCanvas = this.get('fabricCanvas'),
          centerX = fabricCanvas.backgroundImage.width / 2,
          centerY = fabricCanvas.backgroundImage.height / 2;

      shapes[0].set({ left: centerX + DEFAULT_OFFSETS[0], top: centerY + DEFAULT_OFFSETS[1] });
      shapes[0].setCoords();
      this.objectMoving({ target: shapes[0] });
      shapes[1].set({ left: centerX + DEFAULT_OFFSETS[2], top: centerY + DEFAULT_OFFSETS[3] });
      shapes[1].setCoords();
      this.objectMoving({ target: shapes[1] });
    },

    addObservers: function addObservers() {
      var fabricCanvas = this.get('fabricCanvas');

      if (!fabricCanvas) {
        return;
      }

      fabricCanvas.on('object:added', this.canvasUpdated.bind(this));
      fabricCanvas.on('object:modified', this.canvasUpdated.bind(this));
      fabricCanvas.on('object:moving', this.objectMoving.bind(this));
    },

    canvasUpdated: function canvasUpdated() {
      this.sendAction('onChange');
    },

    objectMoving: function objectMoving(e) {
      var p = e.target,
          fabricCanvas = this.get('fabricCanvas');

      p.line1 && p.line1.set({ 'x2': p.left + 12, 'y2': p.top + 12 });
      p.line2 && p.line2.set({ 'x1': p.left + 12, 'y1': p.top + 12 });
      fabricCanvas.renderAll();
    },

    registerWithParent: function registerWithParent() {
      this.sendAction('onRegister', this);
    },

    /* To support deleting using delete/backspace */
    didRender: function didRender() {
      this.$().attr({ tabindex: 1 });
    },

    validateMap: function validateMap() {
      var fabricCanvas = this.get('fabricCanvas'),
          size = this.get('referenceSize');

      if (!fabricCanvas) {
        return;
      }

      if (!size) {
        alert('Please enter a known size in cm');
        return false;
      }

      var sizeFloat = parseFloat(size);

      if (isNaN(sizeFloat) || sizeFloat <= 0) {
        alert('Please enter a known size in cm');
        return false;
      }

      return true;
    },

    updateReferenceSize: function updateReferenceSize(size) {
      this.set('referenceSize', size);
      this.sendAction('onChange');
    },

    distanceRatio: _ember['default'].computed('shapes.@each.left', 'shapes.@each.top', 'referenceSize', function () {
      var shapes = this.get('shapes'),
          size = parseFloat(this.get('referenceSize')),
          x = shapes[0].left - shapes[1].left,
          y = shapes[0].top - shapes[1].top;

      return Math.sqrt(x * x + y * y) / size;
    }),

    save: function save() {
      if (!this.validateMap()) return false;

      var ajax = this.get('ajax'),
          shapes = this.get('shapes'),
          testId = this.get('test.id'),
          uri = '/api/tests/' + testId + '/data',
          data = {
        schema: 'calibration',
        data: {
          coords: [shapes[0].left, shapes[0].top, shapes[1].left, shapes[1].top],
          size: parseFloat(this.get('referenceSize'))
        },
        meta_data: {
          manual: true
        },
        distance_ratio: this.get('distanceRatio')
      };

      return ajax.request(uri, {
        method: 'post',
        data: JSON.stringify(data),
        contentType: 'application/json'
      });
    },

    actions: {
      updateReferenceSize: function updateReferenceSize(size) {
        this.updateReferenceSize(size);
      },
      resetReferenceLine: function resetReferenceLine() {
        this.resetMap();
      }
    }
  });
});
/* globals fabric */