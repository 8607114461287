define('bc-frontend/components/ivy-videojs-player/component', ['exports', 'ember', 'ivy-videojs/components/ivy-videojs-player', 'videojs'], function (exports, _ember, _ivyVideojsComponentsIvyVideojsPlayer, _videojs) {
  exports['default'] = _ivyVideojsComponentsIvyVideojsPlayer['default'].extend({
    offsetStart: null,

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.updateOffset();
    },

    onOffsetChange: _ember['default'].observer('offsetStart', function () {
      this.updateOffset();
    }),

    updateOffset: function updateOffset() {
      var start = this.get('offsetStart');

      if (this.get('offsetStart')) {
        (0, _videojs['default'])(this.element).offset({ start: start });
      }
    }
  });
});