define('bc-frontend/components/schema-tile/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: null,

    tileKey: _ember['default'].computed('schema', function () {
      var schemaLabel = this.get('schema.label');
      var suffix = '';

      if (schemaLabel === 'Calibration Video') {
        suffix = 'calibration';
      } else if (schemaLabel === 'Centroid Activity') {
        suffix = 'centroid';
      } else if (schemaLabel === 'Tracking Boundary') {
        suffix = 'tracking-boundary';
      } else if (schemaLabel === 'Activity Analysis') {
        suffix = 'analysis';
      } else if (schemaLabel === 'Zoned Activity Analysis') {
        suffix = 'zoned-analysis';
      } else if (schemaLabel === 'Experiment Video') {
        suffix = 'video';
      } else if (schemaLabel === 'Zone Map') {
        suffix = 'zone';
      } else if (schemaLabel === 'Zoned Sequence Analysis') {
        suffix = 'zone-sequence';
      } else if (schemaLabel === 'Boundary Mask') {
        suffix = 'boundary-mask';
      } else if (schemaLabel === 'Thumbnail') {
        suffix = 'thumbnail';
      } else if (schemaLabel === 'Spontaneous Alternation') {
        suffix = 'alternation';
      } else if (schemaLabel === 'Video Path') {
        suffix = 'video-path';
      } else if (schemaLabel === 'Trace Path') {
        suffix = 'trace-path';
      } else if (schemaLabel === 'Manual Scoring Setup') {
        suffix = 'manual-scoring-setup';
      } else if (schemaLabel === 'Manual Scoring Data') {
        suffix = 'manual-scoring-data';
      } else if (schemaLabel === 'Manual Scoring Analysis') {
        suffix = 'manual-scoring-analysis';
      } else if (schemaLabel === 'Generic Photobeam Data') {
        suffix = 'photobeam-data';
      } else if (schemaLabel === 'Photobeam Activity Counts') {
        suffix = 'photobeam-activity';
      } else {
        return null;
      }
      return 'schema-tile/' + suffix;
    })
  });
});