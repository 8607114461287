define('bc-frontend/components/time-display/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: '',

    minutes: _ember['default'].computed('time', function () {
      return parseInt(this.get('time') / 60);
    }),
    seconds: _ember['default'].computed('time', function () {
      return this.get('time') % 60;
    })
  });
});