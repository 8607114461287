define('bc-frontend/components/tests/status-dialog/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    hadError: _ember['default'].computed.equal('model.status', 'error'),
    wasAborted: _ember['default'].computed.equal('model.status', 'aborted'),

    actions: {
      close: function close() {
        this.sendAction('onClose');
      }
    }
  });
});