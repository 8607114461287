define('bc-frontend/test/data/controller', ['exports', 'ember', 'bc-frontend/config/environment'], function (exports, _ember, _bcFrontendConfigEnvironment) {
  exports['default'] = _ember['default'].Controller.extend({
    ajax: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),
    store: _ember['default'].inject.service(),
    pusher: _ember['default'].inject.service(),

    thumbnailUrl: _ember['default'].computed('model.thumbnailUrl', 'thumbnailStamp', function () {
      var thumbnailUrl = this.get('model.thumbnailUrl'),
          stamp = this.get('thumbnailStamp'),
          dataHost = _bcFrontendConfigEnvironment['default']['apiHost'];

      // ** TODO: BEGIN HACKY SECTION
      if (thumbnailUrl && this.get('inCalibrationMode') && this.get('lastRatio')) {
        var urlSections = thumbnailUrl.split('.');
        thumbnailUrl = urlSections[0] + '_cal.' + urlSections[1];
      }
      // ** END HACKY SECTION

      if (thumbnailUrl && stamp) {
        var stampInt = stamp.valueOf();
        return '' + dataHost + thumbnailUrl + '?stamp=' + stampInt;
        // return `${thumbnailUrl}?stamp=${stampInt}`;
      }

      return null;
    }),

    thumbnailStamp: null,
    elapsed: 0,
    lastRatio: null,
    isSaving: false,

    isAborted: _ember['default'].computed.equal('model.status', 'aborted'),
    hadError: _ember['default'].computed.equal('model.status', 'error'),
    isCreated: _ember['default'].computed.equal('model.status', 'created'),
    isCalibrating: _ember['default'].computed.equal('model.status', 'calibrating'),
    isCalibrated: _ember['default'].computed.equal('model.status', 'calibrated'),
    isClearing: _ember['default'].computed.equal('model.status', 'clearing'),
    isCleared: _ember['default'].computed.equal('model.status', 'cleared'),
    isInserting: _ember['default'].computed.equal('model.status', 'inserting'),
    isInserted: _ember['default'].computed.equal('model.status', 'inserted'),
    isCollecting: _ember['default'].computed.equal('model.status', 'collecting'),
    isCompleted: _ember['default'].computed.equal('model.status', 'completed'),
    isAwaitingInput: _ember['default'].computed.equal('model.status', 'meta'),

    inCalibrationMode: _ember['default'].computed.or('isCalibrating', 'isCalibrated'),
    inMetaMode: _ember['default'].computed.or('isAwaitingInput'),
    inClearingMode: _ember['default'].computed.or('isClearing', 'isCleared'),
    inInsertingMode: _ember['default'].computed.or('isInserting', 'isInserted'),
    inCollectionMode: _ember['default'].computed.or('isCollecting'),

    shouldShowAbort: _ember['default'].computed.or('isCreated', 'isCalibrating', 'isCollecting', 'isClearing', 'isCleared', 'isInserting', 'isInserted'),
    shouldShowDownload: _ember['default'].computed.and('model.canExport', 'isCompleted'),

    exportLink: _ember['default'].computed('model', 'session.session.authenticated.token', function () {
      var token = this.get('session.session.authenticated.token'),
          dataSetId = this.get('model.id'),
          dataHost = _bcFrontendConfigEnvironment['default']['apiHost'];

      var requestObj = {
        'token': token
      };
      var q = btoa(decodeURIComponent(encodeURIComponent(JSON.stringify(requestObj))));

      return dataHost + '/api/dataSets/' + dataSetId + '/csv?q=' + q;
    }),

    initialize: function initialize() {
      this.set('thumbnailStamp', null);
      this.set('elapsed', 0);
      this.set('lastRatio', null);
    },

    onCalibrationUpdate: function onCalibrationUpdate(data) {
      this.set('lastRatio', data.ratio);
      this.set('thumbnailStamp', new Date());
    },

    onThumbnailUpdated: function onThumbnailUpdated(data) {
      this.set('elapsed', data.elapsed);
      this.set('thumbnailStamp', new Date());
    },

    onCollectionCompleted: function onCollectionCompleted(data) {
      this.set('isSaving', false);

      if (data.status === 'completed') {
        if (data.next) {
          this.set('showNextSubject', true);
        } else {
          this.transitionToRoute('test');
        }
      } else {
        this.set('showStatusDialog', true);
      }
    },

    onSavePending: function onSavePending() {
      this.set('isSaving', true);
    },

    finishCalibration: function finishCalibration() {
      var self = this,
          recordId = this.get('model.id'),
          ajax = this.get('ajax'),
          requestUri = '/api/dataSets/' + recordId + '/calibrate';

      var payload = {
        dataSet: {}
      };

      ajax.request(requestUri, {
        method: 'post',
        data: JSON.stringify(payload),
        contentType: 'application/json'
      }).then(function () {
        self.get('model').reload();
      }, function () {
        alert('failed!');
      });
    },

    confirmCleared: function confirmCleared() {
      var self = this,
          recordId = this.get('model.id'),
          ajax = this.get('ajax'),
          requestUri = '/api/dataSets/' + recordId + '/cleared';

      var payload = {
        dataSet: {}
      };

      ajax.request(requestUri, {
        method: 'post',
        data: JSON.stringify(payload),
        contentType: 'application/json'
      }).then(function () {
        self.get('model').reload();
      }, function () {
        alert('failed!');
      });
    },

    confirmInserted: function confirmInserted() {
      var self = this,
          recordId = this.get('model.id'),
          ajax = this.get('ajax'),
          requestUri = '/api/dataSets/' + recordId + '/inserted';

      var payload = {
        dataSet: {}
      };

      ajax.request(requestUri, {
        method: 'post',
        data: JSON.stringify(payload),
        contentType: 'application/json'
      }).then(function () {
        self.get('model').reload();
      }, function () {
        alert('failed!');
      });
    },

    actions: {
      useCalibration: function useCalibration() {
        this.finishCalibration();
      },

      confirmClearing: function confirmClearing() {
        this.confirmCleared();
      },

      confirmInserted: function confirmInserted() {
        this.confirmInserted();
      }
    }
  });
});