define('bc-frontend/mixins/data-set-route-mixin', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    dsUpdates: _ember['default'].inject.service(),

    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var dsUpdates = this.get('dsUpdates');
      dsUpdates.activateDS(controller, model.get('id'));
    },

    deactivate: function deactivate() {
      this._super();

      var dsUpdates = this.get('dsUpdates');
      dsUpdates.deactivateDS();
    }
  });
});