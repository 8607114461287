define('bc-frontend/test/boundary/controller', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var inject = _ember['default'].inject;
  exports['default'] = _ember['default'].Controller.extend({
    ajax: inject.service(),

    testController: inject.controller('test'),
    test: computed.alias('testController.model'),

    latestBoundary: computed.alias('model.firstObject'),
    dataSets: computed.alias('model'),
    dataSetsCount: computed.alias('dataSets.length'),
    hasData: computed.gte('dataSetsCount', 1),

    canCollect: computed.alias('model.meta.canCollect'),
    showCollectButton: computed.alias('canCollect'),

    backgroundUri: computed.alias('model.meta.backgroundUri'),

    editorComponent: null,
    boundaryModified: false,
    preventTransition: _ember['default'].computed.alias('boundaryModified'),
    isSaving: false,

    saveBoundary: function saveBoundary() {
      var self = this,
          testId = this.get('test.id'),
          ajax = this.get('ajax'),
          requestUri = '/api/tests/' + testId + '/boundary',
          editorComponent = this.get('editorComponent'),
          exportedBoundary = editorComponent.exportSVG();

      var payload = {
        test: {
          svg: exportedBoundary
        }
      };

      this.set('isSaving', true);

      ajax.request(requestUri, {
        method: 'post',
        data: JSON.stringify(payload),
        contentType: 'application/json'
      }).then(function () {
        self.set('boundaryModified', false);
        self.set('isSaving', false);
      }, function () {
        alert('Failed to save tracking boundary. Please try again momentarily.');
        self.set('isSaving', false);
      });
    },

    actions: {
      registerEditor: function registerEditor(editor) {
        this.set('editorComponent', editor);
      },
      saveBoundary: function saveBoundary() {
        this.saveBoundary();
      }
    }
  });
});