define('bc-frontend/components/video-calibration/auto/component', ['exports', 'ember', 'bc-frontend/config/environment'], function (exports, _ember, _bcFrontendConfigEnvironment) {
  var computed = _ember['default'].computed;
  var inject = _ember['default'].inject;
  exports['default'] = _ember['default'].Component.extend({
    ajax: inject.service(),
    store: inject.service(),

    // supplied via param
    test: null,
    model: null,

    latestCalibration: computed.alias('model.firstObject'),
    dataSetCount: computed.alias('model.length'),
    hasData: computed.gte('dataSetCount', 1),

    showCollectButton: computed.not('hasData'),

    thumbnailUrl: _ember['default'].computed('latestCalibration', 'thumbnailStamp', function () {
      var model = this.get('latestCalibration'),
          modelId = model.get('id'),
          stamp = this.get('thumbnailStamp'),
          calSuffix = '',
          dataHost = _bcFrontendConfigEnvironment['default']['apiHost'];

      if (this.get('inCalibrationMode') && this.get('lastRatio')) {
        calSuffix = '_cal';
      }

      if (model && stamp) {
        var stampInt = stamp.valueOf();
        return dataHost + '/data/' + modelId + '_thumb' + calSuffix + '.jpg?stamp=' + stampInt;
      }

      return null;
    }),

    thumbnailStamp: null,
    lastRatio: null,

    isAborted: _ember['default'].computed.equal('latestCalibration.status', 'aborted'),
    hadError: _ember['default'].computed.equal('latestCalibration.status', 'error'),
    isCreated: _ember['default'].computed.equal('latestCalibration.status', 'created'),
    isCalibrating: _ember['default'].computed.equal('latestCalibration.status', 'calibrating'),
    isCalibrated: _ember['default'].computed.equal('latestCalibration.status', 'calibrated'),
    isCompleted: _ember['default'].computed.equal('latestCalibration.status', 'completed'),

    inCalibrationMode: _ember['default'].computed.or('isCalibrating', 'isCalibrated'),

    shouldShowAbort: _ember['default'].computed.or('isCreated', 'isCalibrating'),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.initialize();
    },

    initialize: function initialize() {
      this.set('thumbnailStamp', null);
      this.set('lastRatio', null);
    },

    onCalibrationUpdate: function onCalibrationUpdate(data) {
      this.set('lastRatio', data.ratio);
      this.set('thumbnailStamp', new Date());
    },

    onSavePending: function onSavePending() {
      this.set('isSaving', true);
    },

    finishCalibration: function finishCalibration() {
      var self = this,
          recordId = this.get('latestCalibration.id'),
          ajax = this.get('ajax'),
          requestUri = '/api/dataSets/' + recordId + '/calibrate';

      var payload = {
        dataSet: {}
      };

      ajax.request(requestUri, {
        method: 'post',
        data: JSON.stringify(payload),
        contentType: 'application/json'
      }).then(function () {
        self.get('latestCalibration').reload();
      }, function () {
        alert('failed!');
      });
    },

    actions: {
      useCalibration: function useCalibration() {
        this.finishCalibration();
      },
      recalibrate: function recalibrate() {
        this.sendAction('onAutoCalibrate');
      }
    }
  });
});