define('bc-frontend/data-set/model', ['exports', 'bc-frontend/config/environment', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'ember'], function (exports, _bcFrontendConfigEnvironment, _emberDataModel, _emberDataAttr, _emberDataRelationships, _ember) {
  var computed = _ember['default'].computed;
  exports['default'] = _emberDataModel['default'].extend({
    test: (0, _emberDataRelationships.belongsTo)('test'),
    // TODO
    subject: (0, _emberDataAttr['default'])('string'),

    url: (0, _emberDataAttr['default'])('string'),
    thumbnailUrl: (0, _emberDataAttr['default'])('string'),

    clearing: (0, _emberDataAttr['default'])('date'),
    collecting: (0, _emberDataAttr['default'])('date'),
    complete: (0, _emberDataAttr['default'])('date'),
    converting: (0, _emberDataAttr['default'])('date'),
    created: (0, _emberDataAttr['default'])('date'),
    inserting: (0, _emberDataAttr['default'])('date'),
    updated: (0, _emberDataAttr['default'])('date'),

    originData: (0, _emberDataRelationships.belongsTo)('data-set', {
      inverse: null
    }),

    originDevice: (0, _emberDataRelationships.belongsTo)('device', {
      inverse: null
    }),

    // typeSlug: attr('string'),
    metaData: (0, _emberDataAttr['default'])(),

    dataInternalUri: (0, _emberDataAttr['default'])('string'),
    dataPublicUri: (0, _emberDataAttr['default'])('string'),

    duration: (0, _emberDataAttr['default'])(),
    status: (0, _emberDataAttr['default'])('string'),
    reason: (0, _emberDataAttr['default'])('string'),

    schema: (0, _emberDataRelationships.belongsTo)('dataSetSchema', {
      inverse: null
    }),

    siblingDataSets: (0, _emberDataAttr['default'])('string', {
      defaultValue: '{}'
    }),

    canExport: (0, _emberDataAttr['default'])('boolean'),
    isRealtime: (0, _emberDataAttr['default'])('boolean'),
    realtimeId: (0, _emberDataAttr['default'])('string'),
    realtimeUri: (0, _emberDataAttr['default'])('string'),

    isAppending: _ember['default'].computed.equal('status', 'append'),
    isClearing: _ember['default'].computed.equal('status', 'clearing'),
    isInserting: _ember['default'].computed.equal('status', 'inserting'),
    isCollecting: _ember['default'].computed.equal('status', 'collecting'),
    isQueued: _ember['default'].computed.equal('status', 'queued'),
    isAnalyzing: _ember['default'].computed.equal('status', 'analyzing'),
    isConverting: _ember['default'].computed.equal('status', 'converting'),
    isRealtimeAppending: _ember['default'].computed.and('isRealtime', 'isAppending'),
    isReceiving: _ember['default'].computed.or('isRealtimeAppending', 'isClearing', 'isInserting', 'isCollecting'),

    isOrigin: _ember['default'].computed.not('originData.id'),
    isDestination: _ember['default'].computed.not('isOrigin'),
    isLiveOrigin: _ember['default'].computed.and('isOrigin', 'isReceiving'),
    isLiveDestination: _ember['default'].computed.and('isDestination', 'isReceiving'),

    realSiblings: computed('siblingDataSets', function () {
      var siblingDataSets = this.get('siblingDataSets');
      if (siblingDataSets) {
        return JSON.parse(siblingDataSets);
      }
      return {};
    }),

    playbackUri: computed(function () {
      var url = this.get('url');

      if (url.indexOf("://") >= 0) {
        return url;
      }

      var dataHost = _bcFrontendConfigEnvironment['default']['apiHost'];

      return '' + dataHost + url;
    }),

    thumbUri: computed(function () {
      return this.get('thumbnailUrl');
    }),

    displayLabel: (0, _emberDataAttr['default'])('string'),

    lastSample: computed(function () {
      return {};
    })

  });
});