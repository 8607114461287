define('bc-frontend/components/manual-scoring-configuration-editor/edit-shortcut-dialog/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    shortcut: _ember['default'].computed.reads('behavior.key'),

    actions: {
      confirm: function confirm() {
        var behavior = this.get('behavior'),
            shortcut = this.get('shortcut');

        this.sendAction('onConfirm', behavior, shortcut);
      },
      cancel: function cancel() {
        this.sendAction('onClose');
      }
    }
  });
});