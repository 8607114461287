define('bc-frontend/pusher/service', ['exports', 'ember', 'bc-frontend/config/environment'], function (exports, _ember, _bcFrontendConfigEnvironment) {

  var pusherKey = _bcFrontendConfigEnvironment['default']['pusher-key'];

  exports['default'] = _ember['default'].Service.extend({
    session: _ember['default'].inject.service(),

    pusher: _ember['default'].computed('session.session.authenticated.token', function () {
      // Enable pusher logging - don't include this in production
      if (_bcFrontendConfigEnvironment['default'].environment === 'development') {
        Pusher.logToConsole = true;
      }

      var authToken = this.get('session.session.authenticated.token');

      var pusher = new Pusher(pusherKey, {
        encrypted: true,
        authEndpoint: _bcFrontendConfigEnvironment['default']['apiHost'] + '/api/pusher/auth',
        auth: {
          headers: {
            'Authorization': 'JWT ' + authToken
          }
        }
      });

      return pusher;
    })
  });
});
/* globals Pusher */