define('bc-frontend/user/model', ['exports', 'ember', 'ember-data/model', 'ember-data/attr'], function (exports, _ember, _emberDataModel, _emberDataAttr) {
  // import { belongsTo, hasMany } from 'ember-data/relationships';

  exports['default'] = _emberDataModel['default'].extend({
    email: (0, _emberDataAttr['default'])('string'),
    firstName: (0, _emberDataAttr['default'])('string'),
    lastName: (0, _emberDataAttr['default'])('string'),

    displayName: _ember['default'].computed('email', 'firstName', 'lastName', function () {
      var email = this.get('email'),
          first = this.get('firstName'),
          last = this.get('lastName');

      if (first) {
        return first + ' ' + last;
      }

      return email;
    }),

    numDevices: (0, _emberDataAttr['default'])('number')
  });
});