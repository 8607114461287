define('bc-frontend/components/video-calibration/component', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var inject = _ember['default'].inject;
  exports['default'] = _ember['default'].Component.extend({
    ajax: inject.service(),
    store: inject.service(),

    // supplied via param
    test: null,
    model: null,

    dataModified: false,
    isSaving: false,

    latestCalibration: computed.alias('model.firstObject'),
    latestIsValid: computed.equal('latestCalibration.status', 'completed'),
    dataSetCount: computed.alias('model.length'),
    hasData: computed.gte('dataSetCount', 1),

    showManualCalibration: computed.reads('latestCalibration.metaData.manual'),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.initialize();
    },

    initialize: function initialize() {},

    saveChanges: function saveChanges() {
      var self = this;

      if (!this.get('manualComponent').validateMap()) {
        return;
      }

      this.set('isSaving', true);
      this.get('manualComponent').save().then(function () {
        self.set('isSaving', false);
        self.set('dataModified', false);
      });
    },

    actions: {
      saveChanges: function saveChanges() {
        this.saveChanges();
      }
    }
  });
});