define('bc-frontend/components/dataset-detail/layouts/manual-scoring-data/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    video: _ember['default'].computed.alias('dataSet.realSiblings.video'),
    configuration: _ember['default'].computed.alias('dataSet.realSiblings.manual-scoring-setup'),

    actions: {
      modifiedChanged: function modifiedChanged(flag) {
        this.sendAction('onModifiedChanged', flag);
      }
    }
  });
});