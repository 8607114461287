define('bc-frontend/activatepw/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    ajax: _ember['default'].inject.service(),

    setPassword: function setPassword() {
      var self = this;

      var _get = this.get('model');

      var user_id = _get.user_id;
      var token = _get.token;
      var password = this.get('password');

      var ajax = this.get('ajax'),
          payload = {
        confirm: {
          id: user_id,
          token: token,
          password: password
        }
      };

      this.set('isPending', true);

      // return this.get('store').findRecord('user', params.user_id);
      ajax.request('/api/auth/confirm', {
        method: 'post',
        data: JSON.stringify(payload),
        contentType: 'application/json'
      }).then(function () {
        self.set('submitResponse', true);
      }, function () {
        self.set('submitResponse', false);
      })['finally'](function () {
        self.set('isSubmitted', true);
      });
    },

    actions: {
      setPassword: function setPassword() {
        this.setPassword();
      }
    }
  });
});